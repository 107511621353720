import React, { useState } from "react";
import { ImgUrl } from "../services";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export default function GLightBox(props) {
  const [photoIndex, setPhotoIndex] = useState(props.selectedIndex || 0);
  const [images, setImages] = useState(props.images || []);
  console.log(images);
  return (
    <div>
      {images.length > 0 ? (
        <Lightbox
        open={props.isImgPreview}
        index={photoIndex}
        // on={{ view: ({ index: currentIndex }) => setPhotoIndex(currentIndex)  }}
        animation={{ fade: 0 }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        close={() => props.setImgPreview(false)} 
        slides={images}
        render={{
          buttonPrev: images.length <= 1 ? () => null : undefined,
          buttonNext: images.length <= 1 ? () => null : undefined,
        }}
        />
      ) : (
        ""
      )}
    </div>
  );
}
