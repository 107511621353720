import React, { useState, useEffect, useRef, useContext } from "react";
import { ApiService } from "../services";
import CustomSeparator from "../Elements/gBreadCrumbs";
import GInfo from "../Elements/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import GItemTable from "../Elements/gCartItems";
import { GTableContent } from "../Elements/gContent";
import { ReactComponent as AccountBalanceWallet } from "../assets/images/wallet.svg";
import { ReactComponent as Send } from "../assets/images/icons/send.svg";
import { ReactComponent as Info } from "../assets/images/info.svg";
import { NoteDiv } from "../styledComponents/notesStyles";
import { MessageBox } from "../styledComponents/messageStyles";
import TooltipComponent from "../Elements/gTooltip";
// import OrderDeliveryInfo from "../components/orderDeliveryInfo";
import TaskView from "../Elements/gTaskView";
import TaskStatusTiming from "../Elements/gTaskStatusView";
import GAgentView from "../Elements/gAssignAgentView";
import CancelReasonsView from "../Components/orderCancelReasons";
import OrderTrack from "../Components/orderTracking";
import CancelBox from "../Elements/gCancelBox";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import GDropdown from "../Elements/gDropdown";
import useSettings from "../useSettings";
import GButton from "../Elements/GButton";
import { useSnackbar } from "notistack";
import { GContent } from "../Elements/gContent";
import { useParams, useNavigate } from "react-router-dom";
import GText from "../Elements/GText";
import Modal from "react-modal";
import { ReactComponent as SupportIcon } from "../assets/images/sidebar/support.svg";
import { createSocketConnection } from "../useLaravelPusher";
import OrderSupportChat from "../Components/orderSupportChat";
import { StringsContext } from "../DataFactory/useStrings";

const fixedDivStyle = {
  display: "flex",
  bottom: "5px",
  right: "0",
  left: "5px",
  width: "95%",
  position: "absolute",
  paddingRight: "10px",
  borderTop: "1px solid #e2e2e2",
  background: "#fff",
  zIndex: "8",
};
const formInputStyle = {
  background: "transparent",
  border: "0",
  padding: "0.8rem",
  fontSize: "0.95rem",
  outline: "0",
  boxShadow: "none",
  width: "90%",
};

export default function OrderDetail(props) {
  const [strings] = useContext(StringsContext);
  let { orderId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [ocrvisible, setOcrvisible] = useState(false);
  const [osvisible, setOSvisible] = useState(false);
  const [noteVal, setNoteValue] = useState("");
  const [messageVal, setMessageValue] = useState("");
  let navigate = useNavigate();
  const [orderData, setOrderData] = useState({
    customer: {},
    order_products: [],
    delivery: {},
    pickup: {},
    payment_summary: {
      data: [],
    },
    cancelled_log: {},
    order_transactions: {},
  });
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [ordernotes, setOrderNotes] = useState([]);
  const [orderMessages, setOrderMessages] = useState([]);
  const [msgBtnDisabled, setMsgBtnDisabled] = useState(false);
  const [noteBtnDisabled, setNoteBtnDisabled] = useState(false);
  const [odTrackvisible, setOdTrackvisible] = useState(false);
  const settings = useSettings(true);
  const ViewDropdownRef = useRef(null);
  const [isViewDropdownActive, setIsViewDropdownActive] = useDetectOutsideClick(
    ViewDropdownRef,
    false
  );

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "orders/" + orderId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data);
          setOrderStatuses(response.data?.options);
          setOrderStatus(response.data.data.status?.title);
          setOrderNotes(response.data.data.order_notes);
          setOrderMessages(response.data.data.order_messages);
        } else {
          setOrderData({
            available: false,
            message: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    createSocketConnection();
    window.Echo.channel("order-updated." + orderId).listen(
      "BCOrderUpdated",
      function (e) {
        console.log(e);
        getOrderDetail();
      }
    );

    navigate(
      {
        pathname: "/order/" + orderId,
      },
      {
        state: "order",
      }
    );

    getOrderDetail();

    return () => {
      window.Echo.leaveChannel(`order-updated.${orderId}`);
    };
  }, [orderId]);

  useEffect(() => {
    if (orderStatuses && orderStatuses.length > 0 && orderStatus) {
      var keepGoing = true;
      const listCopy = [...orderStatuses];
      //Logic to update your list here

      listCopy.forEach((user, index) => {
        if (keepGoing === true) {
          user["color"] = "colored";
          if (user.title === orderStatus) {
            keepGoing = false;
          }
        } else {
          user["color"] = "";
        }
      });
      setOrderStatusList(listCopy);
    }
  }, [orderStatuses, orderStatus]);

  function updateField(selectedFieldId, newValue) {
    if (selectedFieldId === "notes") {
      setNoteBtnDisabled(true);
    }
    if (selectedFieldId === "messages") {
      setMsgBtnDisabled(true);
    }
    var post_note = {
      order_id: orderId,
      user_type: "2",
      user_id: settings?.user?.id,
      text:
        selectedFieldId === "notes"
          ? noteVal
          : selectedFieldId === "messages"
          ? messageVal
          : newValue,
    };
    ApiService({
      method: "POST",
      route: "order/" + selectedFieldId,
      body: post_note,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (selectedFieldId === "notes") {
            setNoteValue("");
            setOrderNotes([...ordernotes, response.data.data]);
          } else {
            setMessageValue("");
            setOrderMessages([...orderMessages, response.data.data]);
          }
        }
        enqueueSnackbar(response.data.message);
        setNoteBtnDisabled(false);
        setMsgBtnDisabled(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleCompleteOrder() {
    ApiService({ method: "POST", route: "order/complete/f/" + orderId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data);
          setOrderStatus(response.data.data?.status?.title);
          //getOrderDetail();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function updateOrderStatus(status) {
    ApiService({ method: "POST", route: "order/" + status + "/" + orderId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data);
          setOrderStatus(response.data.data?.status?.title);
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="mainInnerView" style={{ padding: "20px 30px" }}>
      <h1 style={{ display: "inline-block" }}>#{orderId} &nbsp;&nbsp;</h1>

      {orderStatus !== "Cancelled" && (
        <>
          <a
            href={`${process.env.REACT_APP_BASE_URL}/order-invoice/${orderData?.id}`}
            target="_blank"
            style={{
              fontStyle: "italic",
              fontSize: "15px",
              fontWeight: "600",
              margin: "0 8px",
              textDecoration: "underline",
              color: "#777",
            }}
          >
            {strings.w_view_a4_invoice}
          </a>
          <a
            href={`${process.env.REACT_APP_BASE_URL}/order-thermal-invoice/${orderData?.id}`}
            target="_blank"
            style={{
              fontStyle: "italic",
              fontSize: "15px",
              fontWeight: "600",
              margin: "0 8px",
              textDecoration: "underline",
              color: "#777",
            }}
          >
            {strings.w_view_inoice}
          </a>
        </>
      )}

      {orderData?.order_status &&
      orderData?.order_status !== "cancelled" &&
      orderData?.order_status !== "completed" ? (
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            gap: "8px",
            borderRadius: "4px",
            width: "100px",
            padding: "8px 12px",
            background: "#d8ae36",
            color: "#fff",
            fontSize: "14px",
            lineHeight: "16px",
            margin: "5px 0 0 10px",
            cursor: "pointer",
            float: "right",
          }}
          onClick={() => setOSvisible(true)}
        >
          <span role="img" aria-label="help" className="icon">
            <SupportIcon
              style={{
                width: "15px",
                height: "15px",
                fill: "#fff",
              }}
            />
          </span>
          │ <span>{strings.w_chat_support}</span>
        </div>
      ) : (
        ""
      )}

      {orderData?.order_status &&
      (orderData?.order_status === "pending" ||
        orderData?.order_status === "accepted" ||
        orderData?.order_status === "started" ||
        orderData?.order_status === "ready") ? (
        <CancelBox
          handleClick={() => setOcrvisible(true)}
          handleCompleteForcefully={() => handleCompleteOrder()}
        />
      ) : orderData?.order_status === "cancelled" ? (
        <CancelBox data={orderData.cancelled_log} />
      ) : (
        ""
      )}

      <CustomSeparator
        statusList={orderStatusList}
        class={orderStatus}
        updateStatus={true}
        onUpdateStatus={(status) => updateOrderStatus(status)}
      />

      <div
        style={{
          width: "67%",
          display: "inline-block",
          paddingRight: "15px",
          boxSizing: "border-box",
        }}
      >
        <Card>
          <CardHead>
            <GText
              g4
              semi
              text={`${
                orderData.order_products.length === 1
                  ? orderData.order_products.length + " " + strings.w_item
                  : orderData.order_products.length + " " + strings.w_items
              }`}
              style={{ marginBottom: "10px" }}
            />
          </CardHead>
          <div style={{ maxHeight: "230px", overflow: "auto" }}>
            <GItemTable
              items={orderData?.order_products}
              currencySymbol={settings.currency_symbol}
              orderId={orderData?.id}
              freeItems={orderData?.free_products || []}
            />
          </div>

          <div style={{ textAlign: "right", marginTop: "20px" }}>
            {orderData.payment_summary.data.length > 0 && (
              <div style={{ width: "250px", display: "inline-block" }}>
                {orderData.payment_summary.data.map((p) => {
                  return (
                    <GTableContent
                      key={p.title}
                      title={p.title}
                      value={settings.currency_symbol + "" + p.value}
                      align="right"
                      lAlign="right"
                      color="#777"
                    />
                  );
                })}
                <hr />

                <GTableContent
                  title={strings.w_total}
                  value={
                    settings.currency_symbol +
                      "" +
                      orderData.payment_summary?.total_display || ""
                  }
                  align="right"
                  lAlign="right"
                  bold="550"
                />
              </div>
            )}
          </div>
        </Card>

        {orderData.delivery?.status ? (
          <div
            style={{
              width: "60%",
              display: "inline-block",
              paddingRight: "15px",
              boxSizing: "border-box",
            }}
          >
            <Card>
              <CardHead>
                {strings.w_delivery_task} &nbsp;&nbsp;
                {/* {orderData.delivery.status === "completed" ? (
                  <span
                    onClick={() => setOdInfovisible(true)}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#d8ae36",
                      fontSize: "14px",
                    }}
                  >
                    More Info
                  </span>
                ) : (
                  ""
                )} */}
                <span
                  style={{
                    float: "right",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    padding: "5px 8px",
                    background: "",
                  }}
                >
                  {orderData.delivery.status}
                </span>
              </CardHead>
              <TaskStatusTiming
                data={orderData.delivery}
                updateStatus={false}
                onStatusUpdate={getOrderDetail}
                orderStatus={orderStatus}
              />
              <hr />
              <br />
              <div
                style={{
                  verticalAlign: "top",
                  position: "relative",
                }}
              >
                <CardHead>
                  {settings?.store?.class == "food" && (
                    <GButton
                      variant="linkable"
                      onClick={() => setOdTrackvisible(true)}
                      style={{ float: "right" }}
                    >
                      {strings.w_track_order}
                    </GButton>
                  )}
                  <GText
                    g4
                    semi
                    text={strings.w_driver_details}
                    style={{ marginBottom: 10 }}
                  />
                </CardHead>

                {orderData?.driver_eta && (
                  <div
                    style={{
                      padding: "10px 15px",
                      borderRadius: "4px",
                      background: "#f4f5f9",
                      marginBottom: "10px",
                    }}
                  >
                    <GText text={orderData.driver_eta} />
                  </div>
                )}
                <GAgentView
                  orderStatus={orderStatus}
                  orderId={props.orderId}
                  taskLog={orderData.delivery.log}
                  isReAssign={false}
                  assign={false}
                  isAssign={!orderData.delivery.agent ? false : true}
                  driverData={orderData.delivery.agent}
                  deliveryId={orderData.delivery.id}
                  onAssignTask={() => getOrderDetail()}
                />
              </div>
              <div
                style={{
                  width: "95%",
                  marginTop: "15px",
                }}
              >
                <TaskView
                  task={orderData.delivery}
                  storeTitle={orderData.store.title}
                />
              </div>
              <br />
            </Card>
          </div>
        ) : (
          ""
        )}

        <div
          style={{
            width: "40%",
            display: "inline-block",
            boxSizing: "border-box",
            verticalAlign: "top",
          }}
        >
          <Card>
            <GText
              g4
              semi
              text={strings.w_order_summary}
              style={{ marginBottom: 10 }}
            />

            <GContent
              title={strings.w_order_type}
              value={
                orderData.order_type &&
                orderData.order_type
                  .replace("_", " ")
                  .replace(/\b\w/g, (l) => l.toUpperCase())
              }
            />
            {orderData.delivery_type && (
              <GContent
                title={strings.w_delivery_type}
                value={
                  orderData.delivery_type === "asap"
                    ? "Deliver Immediately"
                    : orderData?.delivery_type
                        .replace("_", " ")
                        .replace(/\b\w/g, (l) => l.toUpperCase())
                }
              />
            )}

            {orderData.pickup_time && (
              <GContent
                title={strings.w_pickup_time}
                value={orderData.pickup_time}
              />
            )}
            {orderData.delivery_time && (
              <GContent
                title={strings.w_delivery_time}
                value={orderData.delivery_time}
              />
            )}
            {orderData.instructions && (
              <GContent
                title={strings.w_instructions}
                value={orderData.instructions}
              />
            )}
            {orderData.delivery_address && !orderData.delivery ? (
              <GContent
                title={strings.w_delivery_address}
                value={orderData.delivery_address}
              />
            ) : (
              ""
            )}
            {orderData.delivery?.distance && (
              <GContent
                title={strings.w_delivery_distance}
                value={
                  orderData.delivery?.distance + " " + settings?.distance_unit
                }
              />
            )}
            <br />
          </Card>
        </div>
      </div>
      <div
        style={{
          width: "33%",
          display: "inline-block",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <Card style={{ padding: "20px 15px 25px" }}>
          <GText
            g4
            semi
            text={strings.w_customer_details}
            style={{ marginBottom: "10px" }}
          />
          <GInfo
            title={orderData.customer?.title}
            key={orderData.customer?.id}
            subtitle={orderData.customer?.phone}
            photo={orderData.customer?.thumb_photo}
            imgType="user"
          />

          <hr style={{ margin: "18px 0" }} />
          <GText g4 semi text={strings.w_payment_details} />

          {orderData.order_transactions.length > 0 ? (
            orderData.order_transactions.map((s) => {
              return (
                <div key={s.id} style={{ borderBottom: "1px solid #e2e2e2" }}>
                  <AccountBalanceWallet
                    style={{
                      float: "right",
                      width: 20,
                      height: 20,
                      fill: "#777",
                    }}
                  />
                  <p
                    style={{
                      textTransform: "capitalize",
                      margin: "15px 0 10px",
                    }}
                  >
                    {settings.currency_symbol +
                      s.amount.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}{" "}
                    {s.gateway && (
                      <span style={{ color: "#b2b2b2", fontStyle: "italic" }}>
                        ({s.gateway})
                      </span>
                    )}
                  </p>

                  <span
                    style={{
                      background: "#" + s.background,
                      padding: "2px 5px",
                      fontSize: "14px",
                    }}
                  >
                    {s.status_label}
                  </span>
                  <p
                    style={{
                      color: s.status === "pending" ? "#a2a2a2" : "#333",
                      fontSize: "14px",
                      margin: "12px 0 15px 0",
                    }}
                  >
                    {s.label}
                  </p>
                </div>
              );
            })
          ) : (
            <React.Fragment>
              <p
                style={{
                  margin: "20px 0 5px",
                }}
              >
                {strings.w_pending_amount} -{" "}
                <span
                  style={{
                    textTransform: "capitalize",
                    margin: "5px 0",
                    color: "#777",
                    fontSize: "15px",
                  }}
                >
                  {settings.currency_symbol}
                  {orderData?.pending_payment?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                  <span style={{ fontStyle: "italic" }}>
                    ({orderData.gateway})
                  </span>
                </span>
              </p>
              <GText
                med
                text={strings.s_no_transaction_yet}
                style={{ margin: "10px 0" }}
              />
            </React.Fragment>
          )}
        </Card>

        <Card
          style={{
            padding: "15px 0px",
            position: "relative",
          }}
        >
          <div style={{ padding: "0 15px" }}>
            <CardHead>
              {strings.w_notes} ({ordernotes.length})
            </CardHead>
          </div>
          {ordernotes && ordernotes.length >= 0 ? (
            <div
              style={{
                padding: "5px 15px",
                maxHeight: "340px",
                overflow: "overlay",
                marginBottom: "40px",
              }}
            >
              {ordernotes.map((p) => {
                return (
                  <NoteDiv key={p.id}>
                    <h5>{p.text}</h5>
                    <p>{p.created}</p>
                  </NoteDiv>
                );
              })}
            </div>
          ) : (
            ""
          )}
          <div style={fixedDivStyle}>
            <input
              type="text"
              value={noteVal}
              id="notes"
              placeholder={strings.w_add_a_note}
              onChange={(e) => setNoteValue(e.target.value)}
              style={formInputStyle}
            />
            <GButton
              variant="linkable"
              disabled={noteBtnDisabled}
              onClick={() => {
                updateField("notes");
              }}
              style={{
                borderRadius: "50%",
                color: "#fff",
                background: "#d8ae36",
                marginTop: "7px",
                height: "30px",
                padding: "3px 5px 0 7px",
              }}
            >
              <Send
                style={{
                  width: "18px",
                  height: "18px",
                  paddingTop: "2px",
                  fill: "#fff",
                }}
              />
            </GButton>
          </div>
        </Card>

        {/* <Card
          style={{
            padding: "15px 0px",
            position: "relative",
            marginBottom: "50px",
          }}
        >
          <div style={{ padding: "0 15px" }}>
            <CardHead>
              Messages ({orderMessages.length})
            </CardHead>
          </div>
          <div
            style={{
              padding: "5px 15px 15px",
              maxHeight: "350px",
              overflow: "overlay",
              marginBottom: "40px",
            }}
          >
            {orderMessages.map((p) => {
              return (
                <div
                  key={p.id}
                  style={{ textAlign: p.user_type === 2 ? "right" : "left" }}
                >
                  <MessageBox
                    className={p.user_type === 2 ? "send" : "received"}
                  >
                    <h5>{p.text}</h5>

                    <p>
                      {p.user_type === 2 ? "" : p.user_title + ", "}
                      {p.created}
                    </p>
                  </MessageBox>
                </div>
              );
            })}
          </div>
          <div style={fixedDivStyle}>
            <input
              type="text"
              value={messageVal}
              id="messages"
              placeholder="Add a message.."
              onChange={(e) => setMessageValue(e.target.value)}
              style={formInputStyle}
            />
            <GButton
              variant="linkable"
              disabled={msgBtnDisabled}
              onClick={() => {
                updateField("messages");
              }}
              style={{
                borderRadius: "50%",
                color: "#fff",
                background: "#d8ae36",
                marginTop: "7px",
                height: "30px",
                padding: "3px 5px 0 7px",
              }}
            >
              <Send
                style={{
                  fill: "#fff",
                  width: "18px",
                  height: "18px",
                  paddingTop: "2px",
                }}
              />
            </GButton>
          </div>
        </Card> */}
      </div>

      <Modal
        isOpen={ocrvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcrvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Order cancel reason Modal"
      >
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrvisible={setOcrvisible}
          linkedId={orderId}
          onSubmit={getOrderDetail}
        />
      </Modal>

      <Modal
        isOpen={odTrackvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOdTrackvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Order Tracking Modal"
      >
        <OrderTrack
          odTrackvisible={odTrackvisible}
          setOdTrackvisible={setOdTrackvisible}
          taskData={orderData.delivery}
          agent={orderData?.delivery?.agent}
          orderId={orderData?.id}
        />
      </Modal>

      <Modal
        isOpen={osvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOSvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Order Support Modal"
      >
        <OrderSupportChat
          osvisible={osvisible}
          setOSvisible={setOSvisible}
          orderId={orderData?.id}
        />
      </Modal>
    </div>
  );
}
