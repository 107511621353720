import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CheckCircle } from "../assets/images/icons/checkCircle.svg";
import { ApiService } from "../services";
import GConfirmDialog from "./gConfirmDialog";
import { useSnackbar } from "notistack";
import { StringsContext } from "../DataFactory/useStrings";

const TaskStatusDiv = styled.div`
  position: relative;

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 25px 0;

    li {
      display: inline-flex;
      padding: 0 25px 5px 0px;
      min-width: 140px;
      max-width: 20%;
      box-sizing: border-box;
      div {
        display: inline-block;
        vertical-align: middle;
        padding-left: 10px;
        box-sizing: border-box;
      }
    }
  }
  svg {
    margin-top: 5px;
    width: 22px;
    height: 22px;
  }
  & h4 {
    font-size: 14px;
    margin: 2px 0;
    font-weight: 400;
    color: #333;
  }
  & p {
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    color: #777;
    line-height: 22px;
  }
`;

export default function GTaskStatusView(props) {
  const [strings] = useContext(StringsContext);
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [selectedTaskStatus, setSelectedTaskStatus] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  function updateTaskStatus() {
    ApiService({
      method: "PUT",
      route: "order/deliveries/" + selectedTaskId,
      body: { status: selectedTaskStatus },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.onStatusUpdate();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <React.Fragment>
      <TaskStatusDiv>
        <ul>
          <li
            style={{
              cursor: !props.updateStatus
                ? "auto"
                : !(
                    props.orderStatus === "Pending" ||
                    props.orderStatus === "Completed" ||
                    props.orderStatus === "Cancelled"
                  ) && !props.data.accepted
                ? "pointer"
                : "auto",
            }}
            onClick={() => {
              if (!props.updateStatus) {
                return;
              }
              if (
                !(
                  props.orderStatus === "Pending" ||
                  props.orderStatus === "Completed" ||
                  props.orderStatus === "Cancelled"
                ) &&
                !props.data.accepted
              ) {
                setSelectedTaskId(props.data.id);
                setSelectedTaskStatus("accepted");
                setDialogOpen(true);
              }
            }}
          >
            <CheckCircle
              style={{ fill: props.data.accepted ? "#333" : "#ccc" }}
            />
            <div style={{ width: "calc(100% - 30px)" }}>
              <p>{strings.w_accepted}</p>
              <h4>{props.data.accepted || "-"}</h4>
            </div>
          </li>
          <li
            style={{
              cursor: !props.updateStatus
                ? "auto"
                : !(
                    props.orderStatus === "Pending" ||
                    props.orderStatus === "Completed" ||
                    props.orderStatus === "Cancelled"
                  ) && !props.data.picked
                ? "pointer"
                : "auto",
            }}
            onClick={() => {
              if (!props.updateStatus) {
                return;
              }
              if (
                !(
                  props.orderStatus === "Pending" ||
                  props.orderStatus === "Completed" ||
                  props.orderStatus === "Cancelled"
                ) &&
                !props.data.picked
              ) {
                setSelectedTaskId(props.data.id);
                setSelectedTaskStatus("picked");
                setDialogOpen(true);
              }
            }}
          >
            <CheckCircle
              style={{ fill: props.data.picked ? "#333" : "#ccc" }}
            />
            <div style={{ width: "calc(100% - 30px)" }}>
              <p>{strings.w_picked}</p>
              <h4>{props.data.picked || "-"}</h4>
            </div>
          </li>
          <li
            style={{
              cursor: !props.updateStatus
                ? "auto"
                : !(
                    props.orderStatus === "Pending" ||
                    props.orderStatus === "Completed" ||
                    props.orderStatus === "Cancelled"
                  ) && !props.data.completed
                ? "pointer"
                : "auto",
            }}
            onClick={() => {
              if (!props.updateStatus) {
                return;
              }
              if (
                !(
                  props.orderStatus === "Pending" ||
                  props.orderStatus === "Completed" ||
                  props.orderStatus === "Cancelled"
                ) &&
                !props.data.completed
              ) {
                setSelectedTaskId(props.data.id);
                setSelectedTaskStatus("completed");
                setDialogOpen(true);
              }
            }}
          >
            <CheckCircle
              style={{ fill: props.data.completed ? "#333" : "#ccc" }}
            />
            <div style={{ width: "calc(100% - 30px)" }}>
              <p>{strings.w_completed}</p>
              <h4>{props.data.completed || "-"}</h4>
            </div>
          </li>
        </ul>
      </TaskStatusDiv>
      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.w_update_task_status}
          text={strings.s_update_status_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            updateTaskStatus();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
