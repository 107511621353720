import React, { useEffect, useState } from "react";
import styled from "styled-components";
import VariantForm from "../forms/productVariantsForm";
import AddVariantForm from "../forms/productVariantsAddForm";

const Tab = styled.a`
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0.6;
  border: 0;
  display: block;
  border-radius: 0px;
  border-left: 2px solid transparent;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-left: 2px solid #d8ae36;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: block;
  /* border-bottom: 1px solid #e2e2e2; */
  margin-bottom: 15px;
  width: 130px;
  max-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #98989c;
    background: #d8ae36;
    border-radius: 8px;
    display: block;
  }
  &::-webkit-scrollbar-track {
    background: #d9e5f4;
  }
`;

export default function ProductVariantsView(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [productVar, setProductVar] = useState(props.productVariants);
  const [selectedTab, setSelectedTab] = useState(props.selectedIndex);
  const [formVisisble, setFormVisible] = useState(false);
  const [selectedVariantType, setSelectedVariantType] = useState({
    data: {},
    options: [],
  });

  useEffect(() => {
    if (props.productId) {
      console.log(props);
      setProductVar(props.productVariants);
      console.log(props.selectedIndex);
      props.productVariants.map((k, v) => {
        if (props.selectedIndex === v) {
          setSelectedVariantType({
            data: k,
            options: k.options,
          });
          setFormVisible(true);
        }
      });
      setIsLoading(false);
    }
    if (!props.productId && props.variantTypes) {
      console.log(props.variantTypes);
      setProductVar(props.variantTypes);
      console.log(props.selectedIndex);
      props.variantTypes.map((k, v) => {
        props.variantTypes[v].options = props.variantTypes[v]?.options || [];
        if (props.selectedIndex === v) {
          setSelectedVariantType({
            data: k,
            options: k?.options || [],
          });
          setFormVisible(true);
        }
      });
      setIsLoading(false);
    }
  }, [props]);

  return (
    <div style={{ display: "flex" }}>
      {isLoading == true ? (
        ""
      ) : (
        <>
          <ButtonGroup>
            {productVar.map((type, i) => (
              <Tab
                key={i}
                active={selectedTab === i}
                onClick={() => {
                  setFormVisible(false);
                  props.setVariantTypeIndex(i);
                  setSelectedVariantType({
                    data: type,
                    options: type.options,
                  });
                  setSelectedTab(i);
                  setTimeout(function () {
                    setFormVisible(true);
                  }, 10);
                }}
              >
                {type.title}
              </Tab>
            ))}
          </ButtonGroup>

          <div style={{ width: "80%" }}>
            {formVisisble === true ? (
              props.productId ? (
                <VariantForm
                  selectedVariantType={selectedVariantType}
                  selectedVariant={selectedTab}
                  productId={props.productId}
                  onVariantRemove={(ind) =>
                    props.handleVariantRemove(selectedTab, ind)
                  }
                  onVariantAdd={(data) => {
                    props.handleVariantAdd(selectedTab, data);
                  }}
                  onVariantEdit={(ndata, i) => {
                    props.handleVariantEdit(selectedTab, ndata, i);
                    if (!props.productId) {
                      props.variantTypes[selectedTab].options[i] = ndata;
                    }
                  }}
                  isVarStockShow={props.isVarStockShow}
                />
              ) : (
                <AddVariantForm
                  selectedVariantType={selectedVariantType}
                  selectedVariant={selectedTab}
                  onVariantRemove={(ind) => {
                    props.handleVariantRemove(selectedTab, ind);
                    if (!props.productId) {
                      props.variantTypes[selectedTab].options.splice(ind, 1);
                    }
                  }}
                  onVariantAdd={(ndata) => {
                    props.handleVariantAdd(selectedTab, ndata);
                    if (!props.productId) {
                      props.variantTypes[selectedTab].options.push(ndata);
                    }
                  }}
                  onVariantEdit={(ndata, i) => {
                    props.handleVariantEdit(selectedTab, ndata, i);
                    if (!props.productId) {
                      props.variantTypes[selectedTab].options[i] = ndata;
                    }
                  }}
                  isVarStockShow={props.isVarStockShow}
                />
              )
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </div>
  );
}
