import * as chrono from "chrono-node";
import moment from "moment/moment";

const useDateFormatter = (text, timeZ) => {
  const parseDate = (text, timeZ) => {
    let date = chrono.parseDate(text, {
      timezone: timeZ === "current" ? "GMT" : "",
    });
    let abc = date ? getDisplayText(date) : date;

    return abc;
  };

  const getDisplayText = (timestamp) => {
    if (!timestamp) {
      return null;
    }
    let date = moment(timestamp);

    let day = "";
    let dateFormatted = "";

    let time = date.format("h:mm A");

    if (date.isSame(moment(), "date")) {
      day = "Today";
    } else if (date.isSame(moment().add(1, "day"), "date")) {
      day = "Tomorrow";
    } else if (date.isSame(moment().subtract(1, "day"), "date")) {
      day = "Yesterday";
    } else {
      day = date.format("ddd");
    }

    if (date.isSame(moment(), "year")) {
      dateFormatted = date.format("D MMM");
    } else {
      dateFormatted = date.format("D MMM YYYY");
    }

    return {
      timestamp:
        timeZ === "current"
          ? moment(timestamp).format("YYYY-MM-DD HH:mm:ss")
          : moment(timestamp).utc().format("YYYY-MM-DD HH:mm:ss"),
      date: [day, dateFormatted].filter((d) => d).join(", "),
      time,
    };
  };

  return { parseDate };
};

export default useDateFormatter;
