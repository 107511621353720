import React, { useState, useRef, useEffect } from "react";
import Pickup from "../assets/images/mark-p.png";
import Dropoff from "../assets/images/mark-d.png";
import driverIcon from "../assets/images/gms-marker.svg";

const GMap = (props) => {
  // console.log(props);
  const googleMapRef = useRef(null);
  let googleMap = null;
  var bounds;

  var numDeltas = 100;
  var delay = 20; //milliseconds
  var i = 0;
  var deltaLat;
  var deltaLng;

  // list of the marker object along with icon
  const [markerList, setMarkerList] = useState([]);
  const [dMarkerList, setDMarkerList] = useState([]);
  console.log(markerList);

  useEffect(() => {
    setDMarkerList([]);

    let coordinates = {
      lat: parseFloat(props.data.pickup_lat),
      lng: parseFloat(props.data.pickup_lng),
      icon: Pickup,
      title: "pickup",
      address: props.data.pickup_address,
      type: "location",
      id: "pickup",
    };

    markerList.push(coordinates);
    setMarkerList([...markerList]);
    let coordinates1 = {
      lat: parseFloat(props.data.delivery_lat),
      lng: parseFloat(props.data.delivery_lng),
      icon: Dropoff,
      title: "delivery",
      address: props.data.delivery_address,
      type: "location",
      id: "delivery",
    };

    markerList.push(coordinates1);
    setMarkerList([...markerList]);

    if (props.agent?.latitude) {
      markerList.push({
        lat: parseFloat(props.agent?.latitude),
        lng: parseFloat(props?.agent?.longitude),
        icon: driverIcon,
        title: props?.agent?.title,
        address: props.agent?.title,
        type: "agent",
        id: props.agent?.id,
      });
      setMarkerList([...markerList]);
    }

    googleMap = initGoogleMap();
    var bounds = new window.google.maps.LatLngBounds();
    // const directionsService = new window.google.maps.DirectionsService();
    // const directionsRenderer = new window.google.maps.DirectionsRenderer({
    //   suppressMarkers: true,
    //   polylineOptions: {
    //     strokeColor: "black",
    //   },
    // });

    // directionsRenderer.setMap(googleMap);
    // calculateAndDisplayRoute(directionsService, directionsRenderer);

    markerList.map((x) => {
      const marker = createMarker(x);
      const infoWindow = createInfoWindow(x);

      window.google.maps.event.addListener(marker, "click", function () {
        infoWindow.close(); // Close previously opened infowindow
        infoWindow.setContent(
          '<p class="infowindowstyle">' + x.address + "</p>"
        );
        infoWindow.open(googleMap, marker);
      });
      if (x.type == "agent") {
        let fInd = dMarkerList.findIndex((m) => m.id == marker.id);
        if (fInd == -1) {
          dMarkerList.push(marker);
          setDMarkerList([...dMarkerList]);
        }
      }
      bounds.extend(marker.position);
    });
    googleMap.fitBounds(bounds); // the map to contain all markers
  }, []);

  useEffect(() => {
    function transition(result) {
      i = 0;
      deltaLat = (result[0] - latitude) / numDeltas;
      deltaLng = (result[1] - longitude) / numDeltas;
      moveMarker();
    }

    function moveMarker() {
      latitude += deltaLat;
      longitude += deltaLng;
      var latlng = new window.google.maps.LatLng(latitude, longitude);
      dMarkerList[index].setPosition(latlng);
      dMarkerList[index].latitude = latitude;
      dMarkerList[index].longitude = longitude;
      // setDMarkerList([...dMarkerList]);
      if (i != numDeltas) {
        i++;
        setTimeout(moveMarker, delay);
      }
    }

    var latitude = null;
    var longitude = null;
    var index = null;

    // console.log(dMarkerList);

    if (dMarkerList.length > 0) {
      dMarkerList.map((d, i) => {
        if (d?.id == props.agent?.id) {
          latitude = d.position.lat();
          longitude = d.position.lng();
          index = i;
          var result = [
            parseFloat(props.agent.latitude),
            parseFloat(props.agent.longitude),
          ];
          transition(result);
          //  var latlng = new window.google.maps.LatLng(props.updatedDriver.latitude, props.updatedDriver.longitude );
          //  setTimeout(d.setPosition(latlng) , delay);
        }
      });
    }
  }, [props.agent]);

  // function calculateAndDisplayRoute(directionsService, directionsRenderer) {
  //   directionsService
  //     .route({
  //       origin: props.data[0]?.lat + "," + props.data[0]?.lng,
  //       destination: props.data[1]?.lat + "," + props.data[1]?.lng,
  //       travelMode: window.google.maps.TravelMode.DRIVING,
  //     })
  //     .then((response) => {
  //       directionsRenderer.setDirections(response);
  //     });
  //   // .catch((e) => window.alert("Directions request failed due to " + status));
  // }

  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      center: {
        lat: parseFloat(props.data?.agent?.latitude),
        lng: parseFloat(props.data?.agent?.longitude),
      },
      zoom: 8,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
    });
  };

  // create marker on google map
  const createMarker = (markerObj) =>
    new window.google.maps.Marker({
      position: { lat: markerObj.lat, lng: markerObj.lng },
      map: googleMap,
      title: markerObj.title,
      id: markerObj.id,
      icon: {
        url: markerObj.icon,
        // set marker width and height
        scaledSize: new window.google.maps.Size(40, 40),
      },
    });

  const createInfoWindow = (markerObj) =>
    new window.google.maps.InfoWindow({
      content: markerObj.address,
    });

  return <div ref={googleMapRef} style={{ width: "100%", height: 400 }} />;
};

export default GMap;

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyAl8q9s5LY5qUCPybXG-FWIp717RMB8IqE'
// })(MapContainer)
