import React from "react";
import GText from "./GText";
import GView from "./GView";
import { ImgUrl, addDefaultSrc } from "../services";
import userPlaceholder from "../assets/images/placeholders/f-placeholder.png";

const GAvtar = (props) => {
  let width = props.width || "65px";
  let height = props.height || "65px";
  return (
    <GView
      style={{
        display: "inline-block",
        verticalAlign: "middle",
        width: width,
        height: height,
        borderRadius: "50%",
      }}
    >
      {props.src ? (
        <img
          src={`${ImgUrl(props.imgType)}/${props.src}`}
          onError={(e) => addDefaultSrc(e, props.imgType)}
          style={{ width: "100%", height: "auto", borderRadius: "50%" }}
          alt={props.alt || ""}
          width={"100%"}
          height={"auto"}
        />
      ) : props.imgType === "user" || props.imgType === "host" ? (
        <img
          src={userPlaceholder}
          style={{ width: "100%", height: "auto", borderRadius: "50%" }}
          alt={props.alt || ""}
          width={"100%"}
          height={"auto"}
        />
      ) : (
        <GText
          text={props?.title?.charAt(0)}
          style={{ textTransform: "uppercase" }}
        />
      )}
    </GView>
  );
};

export default GAvtar;
