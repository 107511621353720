import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowRightAlt } from "../assets/images/arrowRightAlt.svg";
import { ReactComponent as Sort } from "../assets/images/filters/sort.svg";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import { StringsContext } from "../DataFactory/useStrings";

const DIV = styled.div`
  background: transparent;
  user-select: none;
  &:hover {
    background: #e6e7eb !important;
  }
  &.active {
    background: #fadda0;
    &:hover {
      background: #fadda0;
    }
  }
`;

const LI = styled.li`
  padding: 5px 8px;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  line-height: 26px;
  &:hover {
    background: #f4f5f9;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;
const SortOrderbox = styled.div`
  font-weight: 500;
  display: inline-block;
  border-radius: 0px;
  width: fit-content;
  box-shadow: none;
  outline: 0;
  vertical-align: top;
  color: #555;
  font-size: 0.95rem;
  margin-left: auto;

  & span {
    background: #fff;
    padding: 0px 4px;
    margin: 0 2px;
    font-size: 12px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    // vertical-align: middle;
    color: #000;
    &.active {
      background: #666666;
      color: #fff;
    }
  }
`;

function GSorting(props) {
  const [strings] = useContext(StringsContext);
  const wrapperSelectRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(props.defaultvalue);
  const [selectedOrderValue, setSelectedOrderValue] = useState(
    props.defaultOrdervalue
  );
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperSelectRef,
    false
  );

  useEffect(() => {
    setSelectedValue(props.defaultvalue);
    setSelectedOrderValue(props.defaultOrdervalue);
  }, [props]);

  var optionsList = props.data.map((option, i) => {
    return (
      <option key={i} value={option[props.option_value]}>
        {option[props.option_key]}
      </option>
    );
  });

  return (
    <div
      ref={wrapperSelectRef}
      style={Object.assign(
        {},
        { display: "inline-block", position: "relative" },
        props.style
      )}
    >
      <DIV
        style={{
          borderRadius: "4px",
          // height: "34px",
          fontSize: "0.97rem",
          color: "#555",
          boxSizing: "border-box",
          padding: "3px 7px",
          fontWeight: "500",
          cursor: "pointer",
        }}
        onClick={() => {
          setShowFilterList(!showFilterList);
        }}
        className={selectedValue ? "active" : ""}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              display: "inline-flex",
            }}
          >
            <Sort
              style={{
                fill: "#4D4D4D",
                width: "12px",
                height: "12px",
                margin: "0 0 0 2px",
              }}
            />
          </span>

          {selectedValue ? (
            <p
              style={{
                margin: "0",
                padding: "3px 5px 5px",
                textOverflow: "ellipsis",
                maxWidth: "160px",
                whiteSpace: "pre",
                overflow: "hidden",
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              {strings.w_sort_by}{" "}
              <span style={{ textTransform: "capitalize" }}>
                {props.data
                  .filter((f) => f[props.option_value] == selectedValue)
                  .map((c, i) => {
                    return c[props.option_key];
                  })}
              </span>
            </p>
          ) : (
            <span
              style={{
                color: "#555",
                padding: "3px 5px 5px",
                display: "inline-block",
                fontSize: "14px",
                fontWeight: "550",
              }}
            >
              {strings.w_sort}
            </span>
          )}
        </div>
      </DIV>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            boxSizing: "border-box",
            width: "230px",
            padding: "5px",
            borderRadius: "4px",
            border: "1px solid #e2e2e2",
            marginTop: "4px",
            marginLeft: "-15px",
            // top: "-145px",
          }}
        >
          <ul
            style={{
              listStyleType: "none",
              padding: "1px",
              margin: "0",
              maxHeight: "150px",
              overflow: "auto",
            }}
          >
            {props.data.map((c, i) => {
              return (
                <LI
                  key={i}
                  onClick={() => {
                    setSelectedValue(c[props.option_value]);
                    props.onChange(c[props.option_value]);
                  }}
                >
                  {c[props.option_key]}{" "}
                  {c[props.option_value] == selectedValue && (
                    <SortOrderbox id={props.id} className={props.display}>
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedOrderValue("DESC");
                          props.onOrderChange("DESC");
                        }}
                        className={
                          selectedOrderValue === "DESC" ? "active" : ""
                        }
                      >
                        A <ArrowRightAlt /> Z
                      </span>

                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedOrderValue("ASC");
                          props.onOrderChange("ASC");
                        }}
                        className={selectedOrderValue === "ASC" ? "active" : ""}
                      >
                        {" "}
                        Z <ArrowRightAlt /> A
                      </span>
                      {/* <img
                        src={sortAsc}
                        alt=""
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedOrderValue("DESC");
                          props.onOrderChange("DESC");
                        }}
                        className={
                          selectedOrderValue === "DESC" ? "active" : ""
                        }
                      />
                      <img
                        src={sortDesc}
                        alt=""
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedOrderValue("ASC");
                          props.onOrderChange("ASC");
                        }}
                        className={selectedOrderValue === "ASC" ? "active" : ""}
                      /> */}
                    </SortOrderbox>
                  )}
                </LI>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}

      {/* <SortOrderbox id={props.id} className={props.display}>
        {selectedOrderValue === "ASC" ? (
          <img
            src={sortAsc}
            alt=""
            onClick={() => {
              setSelectedOrderValue("DESC");
              props.onOrderChange("DESC");
            }}
          />
        ) : (
          <img
            src={sortDesc}
            alt=""
            onClick={() => {
              setSelectedOrderValue("ASC");
              props.onOrderChange("ASC");
            }}
          />
        )}
      </SortOrderbox> */}
    </div>
  );
}

export default GSorting;
