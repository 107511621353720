import React, { useState, useEffect, useContext } from "react";
import orderIcon from "../assets/images/sidebar/orders.svg";
import productIcon from "../assets/images/sidebar/products.svg";
import reviewIcon from "../assets/images/sidebar/reviews.svg";
import withdrawalIcon from "../assets/images/sidebar/withdrawals.svg";
import couponIcon from "../assets/images/sidebar/promotions.svg";
import reportsIcon from "../assets/images/sidebar/reports.svg";
import { ReactComponent as Add } from "../assets/images/icons/add.svg";
import { ReactComponent as SubdirectoryArrowRight } from "../assets/images/sidebar/arrowRight.svg";
import { ReactComponent as StarRate } from "../assets/images/star-filled.svg";
import { StringsContext } from "../DataFactory/useStrings";
import { Link, useLocation } from "react-router-dom";

export default function SidebarRoutes(props) {
  const location = useLocation();
  const [strings] = useContext(StringsContext);
  const [isMenuOpen, setIsMenuOpen] = useState({});
  const [isActive, setIsActive] = useState(false);
  const menus = [
    {
      icon: orderIcon,
      title: "Active Orders",
      path: "/active-orders",
      state: "aOrder",
      isLink: true,
      isStaff: "order_index",
    },
    {
      icon: orderIcon,
      title: strings.w_orders,
      path: "/orders",
      state: "order",
      isLink: true,
      isStaff: "order_index",
    },
    {
      icon: productIcon,
      title: strings.w_products,
      path: "/products",
      state: "product",
      isLink: true,
      isStaff: "product_index",
      children: [
        {
          icon: "📋",
          title: strings.w_categories,
          path: "/categories",
          state: "product",
          isLink: true,
        },
      ],
    },
    {
      icon: couponIcon,
      title: strings.w_promotions,
      path: "/coupons",
      state: "coupon",
      isLink: true,
      isAdd: true,
      isStaff: "coupon_index",
    },
    {
      icon: reviewIcon,
      title: strings.w_reviews,
      path: "/reviews",
      state: "review",
      isLink: true,
      isStaff: "review_index",
    },
    {
      icon: reportsIcon,
      title: strings.w_holiday_hours,
      state: "holiday_hour",
      isLink: true,
      path: "/holiday-hours",
      isStaff: false,
    },
    {
      icon: withdrawalIcon,
      title: strings.w_wallet,
      state: "wallet",
      isLink: true,
      path: "/wallet",
      isStaff: "store_wallet",
    },
  ];

  useEffect(() => {
    let activeKey = "";
    const found = menus.some((el) => el.path === location.pathname);
    // console.log(found);
    if (!found) {
      setIsMenuOpen({});
      setIsActive(false);
    }

    menus.map((s) => {
      s.children &&
        s.children.map((el) => {
          if (s.path === location.pathname) {
            setIsMenuOpen(s);
          }
          if (el.path === location.pathname) {
            activeKey = s;
            setIsMenuOpen(s);
          }
        });
    });
  }, [location]);

  const handleToggle = (data) => {
    setIsMenuOpen(data);
    setIsActive(true);
  };

  function isPermissionExist(per) {
    let exist = props.settings?.permissions?.includes(per);
    console.log(exist);
    return exist;
  }

  function isStoreManager() {
    let uType = props.settings?.user?.super ? true : false;
    return uType;
  }

  const renderMenuItem = (menu, i) => {
    const { title, path, state, icon, isLink, isAdd, children } = menu;

    return (
      <React.Fragment key={i}>
        <li
          className={
            !state
              ? "pListItem"
              : state === location.state
              ? "pListItem active"
              : "pListItem"
          }
          onClick={() => {
            handleToggle(menu, i);
          }}
          href={"#" + menu.path}
        >
          {isLink === true ? (
            <Link
              className="pLink"
              to={{ pathname: path, state: { state: state } }}
              state={state}
            >
              <span className="pListIcon">
                <img src={icon} alt={title} />
              </span>
              {title}
            </Link>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "2px",
              }}
            >
              <span className="pListIcon">
                <img src={icon} alt={title} />
              </span>
            </div>
          )}
          {state == "review" ? (
            <span className="review">
              <StarRate /> {props.settings?.store?.rating_display?.display}
            </span>
          ) : (
            ""
          )}

          {isAdd == true && (
            <span>
              <Add
                className="addIcon"
                onClick={() => props.handleClick(state)}
              />
            </span>
          )}
        </li>
        {children ? renderSubMenuItem(menu) : ""}
      </React.Fragment>
    );
  };

  const renderSubMenuItem = (menu) => {
    const { children } = menu;

    return (
      <ul
        key={menu.path}
        className={`cList ${
          isMenuOpen.state == menu.state
            ? menu.path
              ? "collapse-in"
              : "collapse"
            : ""
        }`}
        id={menu.path}
      >
        {children &&
          children.map((child, i) => {
            return (
              <li
                className={
                  child.path === location.pathname
                    ? "cListItem active"
                    : "cListItem"
                }
                key={i}
              >
                <Link
                  className="cLink"
                  to={{ pathname: child.path }}
                  state={child.state}
                >
                  <span className="cListIcon">
                    <SubdirectoryArrowRight
                      style={{ fill: "#888", width: "15px", height: 15 }}
                    />
                  </span>
                  {child.title}
                </Link>
              </li>
            );
          })}
      </ul>
    );
  };
  return (
    <React.Fragment>
      {isStoreManager() ? (
        <>
          {menus
            .filter((f) => f.title)
            .map((menu, i) => renderMenuItem(menu, i))}
        </>
      ) : (
        <>
          {menus
            .filter((m) => isPermissionExist(m.isStaff) === true)
            .map((menu, i) => renderMenuItem(menu, i))}
        </>
      )}
    </React.Fragment>
  );
}
