import React, { useContext, useState, useRef, useEffect } from "react";
import { LogoutService, isStoreManager, isPermissionExist } from "../services";
import { useNavigate } from "react-router-dom";
import GView from "../Elements/GView";
import styled from "styled-components";
import ChangePassword from "../forms/ChangePassword";
import EditProfile from "../forms/editProfile";
import GAvtar from "../Elements/GAvtar";
import Modal from "react-modal";
import { Popper, Grow, ClickAwayListener } from "@mui/material";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import GInfo from "../Elements/gInfo";
import { Divider } from "@mui/material";
import GConfirmDialog from "../Elements/gConfirmDialog";
import { ReactComponent as StoreIcon } from "../assets/images/icons/store.svg";
import { ReactComponent as UserIcon } from "../assets/images/icons/manager.svg";
import { ReactComponent as SupportIcon } from "../assets/images/icons/help.svg";
import { ReactComponent as PasswordIcon } from "../assets/images/icons/password.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/icons/logout.svg";
import { createSocketConnection } from "../useLaravelPusher";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { StringsContext } from "../DataFactory/useStrings";
import ChangeLanguage from "../Localisation/ChangeLanguage";

const Header = styled.header`
  background-color: rgb(21, 21, 21);
  height: 50px;

  a {
    padding: 0 10px;
    text-decoration: none;
    color: #333;
    display: flex;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
  }
`;

const UL = styled.ul`
  margin: 0;
  padding: 0 10px;
  list-style-type: none;
  display: flex;
  li {
    padding: 10px 12px;
    svg {
      width: 16px;
      height: 16px;
      fill: #fff;
    }
  }
`;

const LinkDropdown = styled.div`
  position: absolute;
  background: #fff;
  right: -15px;
  z-index: 9;
  top: 12px;
  min-width: 180px;
  box-shadow: 0 0 13px #c1bebe8f;
  border-radius: 5px;

  .paperAdminInfo {
    padding: 15px 15px 10px;
    margin: 0px;
    font-size: 15px;
    line-height: 20px;
  }

  ul {
    display: block;
    list-style-type: none;
    padding: 7px 0;
    margin: 0;
    font-size: 15px;
    li {
      a {
        padding: 8px 20px;
        text-decoration: none;
        color: #333;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        text-align: left;
        font-weight: 550;

        &:hover {
          background: #e2e2e2;
          color: #000;
        }

        svg {
          width: 17px;
          height: 17px;
          fill: #333;
        }
      }
    }
  }
`;

const HeaderView = () => {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState();
  const enqueueSnackbar = useEnqueueSnackbar();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const handleClose = (event) => {
    setOpen(false);
    setPlacement();
  };

  const [cpvisible, setCpvisible] = React.useState(false);
  const [epvisible, setEpvisible] = React.useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleLogout = () => {
    LogoutService("logout")
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          console.log(response);
          localStorage.setItem("a_m_AccessToken", "");
          localStorage.setItem("a_m_RefreshToken", "");
          localStorage.setItem("a_m_ExpiresIn", 0);
          window.location = "/";
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createSocketConnection();
  useEffect(() => {
    window.Echo.channel(`store-updates.${settings?.store?.id}`).listen(
      "BCOrderAssigned",
      function (e) {
        // window.Echo.channel("admin-updates").listen("BCOrderUpdated", function (e) {
        console.log(e);
        enqueueSnackbar(`You have received a new Order #${e?.order_id}`);
      }
    );
    return () => {
      window.Echo.leaveChannel(`store-updates.${settings?.store?.id}`);
      // window.Echo.leaveChannel(`admin-updates`);
    };
  }, []);

  return (
    <Header>
      <div style={{ marginTop: "0", marginBottom: "0" }}>
        <GView
          style={{ display: "flex", padding: "10px 0", alignItems: "center" }}
        >
          <div
            style={{
              display: "inline-flex",
              width: "60%",
              margin: "0 20px",
              position: "relative",
              height: "100%",
              alignItems: "center",
            }}
          >
            {/* <SearchComponent settings={settings} /> */}
          </div>

          <GView
            style={{
              marginLeft: "auto",
              marginRight: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ChangeLanguage />
            <GView
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <a
                role="button"
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-describedby={"id"}
                aria-haspopup="true"
                className="dropdownMenu"
                onClick={handleClick("bottom")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "0 30px 0 10px",
                }}
              >
                <GAvtar
                  title={settings?.user?.first_name}
                  width={"30px"}
                  height={"30px"}
                  src={settings?.user?.photo}
                  imgType="user"
                  alt={settings?.user?.first_name}
                />
                &nbsp;&nbsp;
                <GText
                  text={`${settings?.user?.first_name} ${
                    settings?.user?.last_name || ""
                  }`}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "100px",
                    textAlign: "left",
                    color: "#fff",
                  }}
                />
              </a>

              <Popper
                id={"id"}
                style={{ zIndex: "99", top: 20 }}
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                role={undefined}
                transition
              >
                {({ TransitionProps }) => {
                  return (
                    <Grow {...TransitionProps}>
                      <LinkDropdown>
                        <p className="paperAdminInfo">
                          <GInfo
                            title={settings?.user?.title}
                            key={settings?.user.id}
                            subtitle={settings?.user?.email}
                            photo={settings?.user?.photo}
                            imgType="user"
                          />
                        </p>

                        <Divider />
                        <ClickAwayListener onClickAway={handleClose}>
                          <ul>
                            <li>
                              <a
                                onClick={() => {
                                  setAnchorEl(null);
                                  setOpen(false);
                                  setEpvisible(true);
                                }}
                              >
                                <UserIcon />
                                {strings.w_edit_profile}
                              </a>
                            </li>
                            {isStoreManager() ||
                            isPermissionExist("store_update") ? (
                              <li>
                                <a
                                  onClick={() => {
                                    setAnchorEl(null);
                                    setOpen(false);

                                    navigate(
                                      {
                                        pathname: "/store-details",
                                      },
                                      {
                                        state: "store",
                                      }
                                    );
                                  }}
                                >
                                  <StoreIcon />
                                  {strings.w_edit_store_info}
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            <li>
                              <a
                                onClick={() => {
                                  setAnchorEl(null);
                                  setOpen(false);
                                  navigate(
                                    {
                                      pathname: "/faqs",
                                    },
                                    {
                                      state: "setting",
                                    }
                                  );
                                }}
                              >
                                <SupportIcon />
                                {strings.w_help_and_faqs}
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() => {
                                  setCpvisible(true);
                                  setAnchorEl(null);
                                  setOpen(false);
                                }}
                              >
                                {" "}
                                <PasswordIcon />
                                {strings.w_change_password}
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() => {
                                  setAnchorEl(null);
                                  setOpen(false);
                                  setDialogOpen(true);
                                }}
                              >
                                <LogoutIcon />
                                {strings.w_logout}
                              </a>
                            </li>
                          </ul>
                        </ClickAwayListener>
                      </LinkDropdown>
                    </Grow>
                  );
                }}
              </Popper>
            </GView>
          </GView>
        </GView>
      </div>

      <Modal
        isOpen={cpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCpvisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "310px",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Change password Modal"
      >
        <ChangePassword cpvisible={cpvisible} setCpvisible={setCpvisible} />
      </Modal>
      <Modal
        isOpen={epvisible}
        className="modal modalCentered"
        overlayClassName="modal-overlay"
        onRequestClose={() => setEpvisible(false)}
        style={{
          content: {
            width: "390px",
          },
        }}
        contentLabel="Edit Profile Modal"
      >
        <EditProfile epvisible={epvisible} setEpvisible={setEpvisible} />
      </Modal>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.w_logout}
          text={strings.s_are_you_sure_logout}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleLogout();
          }}
        ></GConfirmDialog>
      )}
    </Header>
  );
};

export default HeaderView;
