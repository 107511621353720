import React, { useContext, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import useDataFctory from "../useDataFactory";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import { StringsContext } from "../DataFactory/useStrings";

export default function CancelReasons(props) {
  const [strings] = useContext(StringsContext);
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: orderCancelReasons,
    setData,
    loading,
  } = useDataFctory("cancel_reasons", false, {
    user_type: 2,
  });

  useEffect(() => {
    console.log(orderCancelReasons);
    if (loading === false) {
      let findInd = orderCancelReasons.findIndex((r) => r.title == "Other");
      if (findInd <= -1) {
        setData([...orderCancelReasons, { title: "Other" }]);
      }
    }
  }, [loading]);

  const initialValues = {
    cancel_reason: "",
    other_reason: "",
  };

  const onSubmit = (values) => {
    console.log(values);
    let sendData = { ...values };
    if (sendData.cancel_reason !== "Other") {
      delete sendData.other_reason;
    } else {
      sendData.cancel_reason = sendData.other_reason;
    }

    ApiService({
      method: "PUT",
      route: "order/cancel/" + props.linkedId,
      body: values,
    }).then((response) => {
      console.log("r" + response.data);
      if (response.data.status_code === 1) {
        props.setOcrvisible(false);
        props.onSubmit();
      }
      enqueueSnackbar(response.data.message);
    });
  };

  const validationSchema = Yup.object({
    cancel_reason: Yup.string().required(strings.w_required),
    other_reason: Yup.string().when("cancel_reason", {
      is: (val) => val == "Other",
      then: () => Yup.string().required(strings.w_required),
    }),
  });

  if (props.ocrvisible === false) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setCpvisible(false)}
        />
        {strings.w_cancel_order}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <label style={{ margin: "15px 15px 5px" }}>
                {strings.s_please_choose_the_cancellation_reason_below}
              </label>
              {orderCancelReasons.length > 0 && (
                <FormikControl
                  control="radio"
                  name="cancel_reason"
                  options={orderCancelReasons || []}
                  key_title="title"
                  custom={true}
                  key_value="title"
                  divstyle={{ display: "block", padding: "5px 0" }}
                />
              )}
              {values.cancel_reason === "Other" ? (
                <FormikControl
                  control="input"
                  as="textarea"
                  placeholder={strings.s_type_reason_here}
                  name="other_reason"
                />
              ) : (
                ""
              )}

              <GButton
                type="submit"
                variant="condensed"
                children={strings.w_cancel_order}
                style={{ width: "calc(100% - 30px)", margin: "20px 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
