import styled from "styled-components";

export const NoteDiv = styled.div`
  & h5 {
    background: #f7f7f7;
    padding: 15px 15px;
    font-size: 15px;
    border-top-right-radius: 22px;
    margin: 15px 0 5px;
    position: relative;
    font-weight: 400;
    white-space: break-spaces;
    &:after {
      content: "";
      position: absolute;
      top: 15px;
      right: 5px;
      transform: rotate(-45deg);
      margin-top: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent #d7d7d7 transparent transparent;
    }
  }
  & p {
    color: #ccc;
    text-align: right;
    font-size: 13px;
    margin: 0 0 10px;
  }
`;
