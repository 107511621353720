import React, { useEffect, useState, useContext, useRef } from "react";
import { ApiService, ImgUrl, FileApiService } from "../services";
import { ReactComponent as Send } from "../assets/images/send.svg";
import { ReactComponent as AttachFile } from "../assets/images/icons/attachment.svg";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import { MessageBox } from "../styledComponents/messageStyles";
import GBackIcon from "../Elements/gBackIcon";
import useDataFctory from "../useDataFactory";
import { StringsContext } from "../DataFactory/useStrings";
import Loader from "../assets/images/loader.svg";
import styled from "styled-components";
import GConfirmDialog from "../Elements/gConfirmDialog";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  console.log(match);
  return match ? match[1] : undefined;
}

const DIV = styled.div`
  display: flex;
  align-items: flex-end;
  bottom: ${getIOSVersion() >= 14 ? "110px" : "0px"};
  right: 0;
  left: 0px;
  position: absolute;
  padding: 10px 10px 10px 10px;
  background: #f3f5f7;
  border: 1px solid #e9e9e9;
  z-index: 9;

  textarea {
    // border-radius: 8px;
    background: #fff;
    border: 0;
    padding: 0 0.6rem;
    font-size: 0.95rem;
    font-family: inherit;
    outline: 0;
    box-shadow: none;
    resize: none;
    flex: 1;
    max-height: 40px;
  }
`;

export default function OrderSupportChat(props) {
  const [initialLoding, setInitialLoading] = useState(true);
  const [strings] = useContext(StringsContext);
  const fileInput = useRef(null);
  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [messageVal, setMessageValue] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    loading,
    Shimmer,
    Placeholder,
    data: messages,
    loadMore,
    pagination: messagesPagination,
    refreshData,
  } = useDataFctory("support_message", true, {
    page: 1,
    order_id: props.orderId,
  });

  useEffect(() => {
    console.log(props);
    if (props.orderId && initialLoding == true) {
      refreshData({
        page: 1,
        order_id: props.orderId,
      });
      setInitialLoading(false);
    }
  }, [props]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behaviour: "smooth", bottom: 0 });
  };
  useEffect(() => {
    if (loading == false) {
      console.log(messagesPagination);
      if (messagesPagination?.current_page < 1 && messages.length > 0) {
        messagesEndRef.current.scrollIntoView({ bottom: 0 });
      }
    }
  }, [messagesPagination, loading, messages]);

  function updateField(mType, fileVal) {
    if (mType == "text" && !messageVal) {
      enqueueSnackbar(strings?.s_please_enter_a_message);
      return;
    }
    setIsBtnDisabled(true);
    var post_note = {
      order_id: props.orderId,
      message_type: mType,
      message: mType == "image" ? fileVal : messageVal,
    };
    ApiService({ method: "POST", route: "support/messages", body: post_note })
      .then((response) => {
        console.log(response.data);
        if (response.status === 26) {
          enqueueSnackbar(
            strings?.s_placeholder_internet_title +
              ". " +
              strings?.s_placeholder_internet_subtitle
          );
          setIsBtnDisabled(false);
          return;
        } else {
          if (response.data.status_code === 1) {
            if (mType == "text") {
              setMessageValue("");
            }
            let findIndex = messages.findIndex(
              (m) => m.id == response.data.data?.id
            );
            if (findIndex == -1) {
              messages.splice(messages, 0, response.data.data);
              refreshData({
                page: 1,
                order_id: props.orderId,
              });
              setTimeout(() => {
                scrollToBottom();
              }, 500);
            }
          }
          setIsBtnDisabled(false);
          enqueueSnackbar(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function enterPressed(event) {
    var code = event.keyCode || event.which;
    // console.log(code);
    // console.log(event.shiftKey);
    if (code === 13 && event.shiftKey) {
      //13 is the enter keycode
      //Do stuff in here
    } else if (code === 13 && !event.shiftKey) {
      event.preventDefault();
      if (isBtnDisabled == false) {
        updateField("text");
      }
    }
  }
  const changeFileHandler = (e) => {
    setUploading(true);
    var files = fileInput.current.files[0];

    FileApiService("POST", "support", files)
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          updateField("image", response.data.image);
          fileInput.current.value = "";
        } else {
          enqueueSnackbar(response.message);
        }

        setUploading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [messageVal]);

  function endChat() {
    ApiService({
      method: "POST",
      route: "support/end-chat",
      body: { order_id: props.orderId },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          refreshData({
            page: 1,
            order_id: props.orderId,
          });
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (props.osvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setOSvisible(false)} />
        {strings.w_chat_support}
        {messages && messages.length > 0 ? (
          <GButton
            variant="linkable"
            onClick={() => {
              setDialogOpen(true);
            }}
            style={{
              marginLeft: "auto",
              fontWeight: 550,
              textDecoration: "underline",
            }}
          >
            {strings.w_end_chat}
          </GButton>
        ) : (
          ""
        )}
      </div>
      <div
        className="modal-body"
        style={{
          height:
            getIOSVersion() >= 14
              ? "calc(100vh - 250px)"
              : "calc(100vh - 120px)",
        }}
      >
        <div
          style={{
            padding: "0 15px 10px",
            maxHeight: "75vh",
            overflow: "overlay",
            marginBottom: "40px",
          }}
        >
          {loading == true ? (
            <Shimmer />
          ) : (
            <>
              {messagesPagination.next_page_url && (
                <GButton
                  variant="linkable"
                  children={strings.w_load_older}
                  type="button"
                  onClick={() => loadMore()}
                  style={{
                    display: "flex",
                    margin: "10px auto",
                    justifyContent: "center",
                    textDecoration: "underline",
                  }}
                />
              )}
              {messages
                .slice(0)
                .reverse()
                .map((p, i) => {
                  return (
                    <div
                      key={p.id}
                      style={{
                        textAlign: p.admin_id ? "left" : "right",
                      }}
                    >
                      <MessageBox className={p.admin_id ? "received" : "send"}>
                        {p.message_type == "image" ? (
                          <img
                            src={ImgUrl("support") + "/" + p.message}
                            style={{
                              width: "200px",
                              borderRadius: "4px",
                            }}
                          />
                        ) : (
                          <h5
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            {p.message}
                          </h5>
                        )}

                        <p
                          style={{
                            padding:
                              p.message_type == "image" ? "2px" : "2px 8px",
                          }}
                        >
                          {p.created}
                        </p>
                      </MessageBox>
                    </div>
                  );
                })}
            </>
          )}
        </div>
        <div ref={messagesEndRef} />
      </div>

      <DIV>
        <input
          type="file"
          id="itemFile"
          ref={fileInput}
          onChange={changeFileHandler}
          style={{ display: "none" }}
        />

        <div
          style={{
            width: "100%",
            padding: "8px 15px",
            display: "flex",
            alignItems: "center",
            background: "#fff",
            borderRadius: "50px",
            gap: "15px",
          }}
        >
          <textarea
            rows={1}
            type="text"
            value={messageVal}
            id="messages"
            wrap="hard"
            placeholder={strings.s_type_message_here}
            onChange={(e) => setMessageValue(e.target.value)}
            onKeyPress={enterPressed}
            ref={textareaRef}
          ></textarea>
          {uploading ? (
            <img
              src={Loader}
              alt="loading"
              style={{ verticalAlign: "middle", width: "25px" }}
            />
          ) : (
            <GButton
              variant="linkable"
              disabled={uploading}
              onClick={() => {
                document.getElementById("itemFile").click();
              }}
              style={{
                height: "25px",
                padding: 0,
              }}
            >
              <AttachFile
                style={{ width: "18px", height: "18px", fill: "#d8ae36" }}
              />
            </GButton>
          )}
          <GButton
            disabled={isBtnDisabled}
            variant="condensed"
            onClick={() => {
              updateField("text");
            }}
            style={{
              borderRadius: "50%",
              color: "#fff",
              height: "30px",
              width: "32px",
              padding: "3px 5px 0 7px",
            }}
          >
            <Send
              style={{
                width: "15px",
                height: "15px",
                fill: "#fff",
              }}
            />
          </GButton>{" "}
        </div>
      </DIV>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.w_end_chat}
          text={strings.s_end_chat}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            endChat();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
      {getIOSVersion() >= 14 ? <div style={{ height: "80px" }}></div> : ""}
    </React.Fragment>
  );
}
