import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import "./assets/fonts/Lato/latofonts.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { SettingsProvider } from "./DataFactory/useSettings";
import { StringsProvider } from "./DataFactory/useStrings";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
  >
    <SettingsProvider>
      <StringsProvider>
        <Router>
          <App />
        </Router>
      </StringsProvider>
    </SettingsProvider>
  </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
