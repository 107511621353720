import React, { useState, useEffect, useContext } from "react";
import { SettingsContext } from "../DataFactory/useSettings";
import pointsEarn from "../assets/images/point-earn.png";
import pointsNotEarn from "../assets/images/point-not-earn.png";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import useDataFctory from "../useDataFactory";
import { useLocation, useNavigate } from "react-router-dom";
import GView from "../Elements/GView";
import QueryWith from "../Query";
import GPagination from "../Elements/gPagination";
import GPaginationSelect from "../Elements/gPaginationSelectbox";
import { TableContainer } from "@mui/material";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import GConfirmDialog from "../Elements/gConfirmDialog";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GSelectbox from "../Elements/gSelectbox";
import { StringsContext } from "../DataFactory/useStrings";

const customDateFilters = [
  {
    title: "Last 7 Days",
    value: "7-days",
  },
  {
    title: "Last 1 Week",
    value: "1-week",
  },
  {
    title: "Last 30 Days",
    value: "30-days",
  },
  {
    title: "Last 1 Month",
    value: "1-month",
  },
  {
    title: "Last 90 Days",
    value: "90-days",
  },
  {
    title: "Last 3 Months",
    value: "3-months",
  },
];

const DateFilterStyles = styled.div`
  text-align: right;
  margin-left: auto;
  .from,
  .to {
    border: 1px solid #e2e2e2;
    background: #fff;
    padding: 5px;
    margin: 2px 0 5px 10px;
    position: relative;
    display: inline-block;
    text-align: left;
    label {
      display: block;
      font-eight: 550;
      padding: 2px;
    }
    .react-datepicker__input-container {
      margin-right: 30px;
    }
    .from .react-datepicker__tab-loop .react-datepicker-popper {
      left: -30px !important;
    }
    .from
      .react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle {
      left: 0 !important;
    }
    .react-datepicker-popper[data-placement^="bottom"] {
      z-index: 9;
    }
    input {
      border: 0;
      outline: 0;
      box-shadow: none;
      font-weight: 450;
    }
  }
`;

const handleFormatChange = (d) => {
  var date = ("0" + new Date(d).getDate()).slice(-2); //Current Date
  var month = ("0" + (new Date(d).getMonth() + 1)).slice(-2); //Current Month
  var year = new Date(d).getFullYear(); //Current Year
  var hour = ("0" + new Date(d).getHours()).slice(-2); //Current Year
  var minute = ("0" + new Date(d).getMinutes()).slice(-2); //Current Year
  var second = ("0" + new Date(d).getSeconds()).slice(-2); //Current Year
  console.log(
    year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second
  );
  return year + "-" + month + "-" + date;
};

export default function Wallet() {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  let params = new URLSearchParams(location.search);
  const [selectedCustomDate, setSelectedCustomDate] = useState("7-days");
  const [filters, setFilters] = useState({
    from: params.get("from")
      ? params.get("from")
      : handleFormatChange(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 7
          )
        ),
    to: params.get("to") ? params.get("to") : handleFormatChange(new Date()),
    page: params.get("page") ? params.get("page") : "1",
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const {
    loading,
    TableShimmer,
    Placeholder,
    data: walletData,
    loadMore,
    pagination,
    refreshData,
    extra,
  } = useDataFctory("wallet", true, filters);

  const columns = [
    { id: "id", label: "", minWidth: 40 },
    { id: "points", label: strings.w_points, minWidth: 100, align: "left" },
    { id: "text", label: strings.w_text, minWidth: 160, align: "center" },
    {
      id: "created_at_formatted",
      label: strings.w_date,
      minWidth: 120,
      align: "center",
    },
  ];

  useEffect(() => {
    var urlParams = QueryWith(location, filters);
    if (urlParams.length > 0) {
      navigate(
        {
          pathname: "/wallet",
          search: urlParams[0],
        },
        { state: "wallet" }
      );
    }
    if (filters?.from && filters?.to) {
      setDateRange([new Date(filters?.from), new Date(filters?.to)]);
    }
    refreshData({ ...filters });
  }, [filters]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  const withdrawWalletPoints = () => {
    setBtnDisabled(true);
    ApiService({ method: "POST", route: "withdrawal" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
        }
        enqueueSnackbar(response.data.message);
        setBtnDisabled(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFilterDates = (customDate) => {
    var curr = new Date();
    var first, last;
    if (customDate === "7-days") {
      first = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() - 7);
      last = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate());
    }
    if (customDate === "1-week") {
      var day = curr.getDay();
      first = new Date(
        curr.getFullYear(),
        curr.getMonth(),
        curr.getDate() + (day === 0 ? -6 : 1) - day - 7
      );
      last = new Date(
        curr.getFullYear(),
        curr.getMonth(),
        curr.getDate() + (day === 0 ? -6 : 1) - day - 1
      );
    }

    if (customDate === "30-days") {
      first = new Date(
        curr.getFullYear(),
        curr.getMonth(),
        curr.getDate() - 29
      );
      last = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate());
    }

    if (customDate === "1-month") {
      first = new Date(curr.getFullYear(), curr.getMonth() - 1, 1);
      last = new Date(curr.getFullYear(), curr.getMonth(), 0);
    }

    if (customDate === "90-days") {
      first = new Date(
        curr.getFullYear(),
        curr.getMonth(),
        curr.getDate() - 89
      );
      last = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate());
    }

    if (customDate === "3-months") {
      first = new Date(curr.getFullYear(), curr.getMonth() - 3, 1);
      last = new Date(curr.getFullYear(), curr.getMonth(), 0);
    }
    setFilters({
      ...filters,
      from: handleFormatChange(first),
      to: handleFormatChange(last),
      page: 1,
    });
  };

  return (
    <div className="mainInnerView">
      <GView
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <GView>
          <GText g1="true" bold text={strings.w_wallet_points} />
          <GText
            style={{ margin: "5px 0 0", letterSpacing: "0.3px" }}
            text={
              strings.w_available_balance +
              " - " +
              settings?.currency_symbol +
              extra?.balance_display
            }
          />
        </GView>

        {/* <DateFilterStyles>
          <GSelectbox
            placeholder=""
            defaultvalue={selectedCustomDate}
            data={customDateFilters}
            id="report_date_filter"
            option_key="title"
            option_value="value"
            showSelectOption={false}
            onChange={(newVal) => {
              console.log(newVal);
              setSelectedCustomDate(newVal);
              handleFilterDates(newVal);
            }}
            style={{
              border: "0px solid #e2e2e2",
              height: "32px",
              padding: "0.45rem 3em 0.45rem 0.45rem",
              background: "transparent",
              marginLeft: "10px",
            }}
          />
          <div>
            <div className="from">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  console.log(update);
                  setDateRange(update);
                  if (update[0] && update[1]) {
                    setFilters({
                      ...filters,
                      from: handleFormatChange(update[0]),
                      to: handleFormatChange(update[1]),
                    });
                  }

                  if (!update[0] && !update[1]) {
                    setFilters({
                      ...filters,
                      from: "",
                      to: "",
                    });
                  }
                }}
                isClearable={true}
                placeholderText={"Select Created Date"}
                dateFormat="dd MMM, yyyy"
              />
            </div>
          </div>
        </DateFilterStyles> */}
      </GView>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10px",
          marginTop: "8px",
        }}
      >
        {pagination && (
          <React.Fragment>
            <GPagination
              count={pagination.total}
              from={pagination.from}
              to={pagination.to}
              onChangeNextPage={() =>
                handleChangePage(pagination.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(pagination.prev_page_url)
              }
              rowsPerPage={pagination.per_page}
              prevDisabled={pagination.prev_page_url ? false : true}
              nextDisabled={pagination.next_page_url ? false : true}
              style={{ marginLeft: "auto" }}
            ></GPagination>
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={pagination.last_page}
              onChangePage={(NewPageNo) =>
                setFilters({ ...filters, page: NewPageNo })
              }
            />
          </React.Fragment>
        )}
        {settings?.user?.balance && settings?.user?.balance > 0 ? (
          <GButton
            variant="condensed"
            disabled={btnDisabled}
            children={strings.w_withdraw}
            style={{ float: "right" }}
            onClick={() => setDialogOpen(true)}
          />
        ) : (
          ""
        )}
      </div>

      <div className="listPaper" style={{ marginTop: "5px" }}>
        {loading === true ? (
          <TableShimmer></TableShimmer>
        ) : walletData && walletData.length > 0 ? (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {walletData.map((c, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData>
                        <img
                          src={c.amount >= 0 ? pointsEarn : pointsNotEarn}
                          alt=""
                          style={{
                            width: "18px",
                            height: "17px",
                            paddingRight: "15px",
                            verticalAlign: "top",
                            paddingTop: "5px",
                          }}
                        />
                      </TableRowData>
                      <TableRowData>{c.amount}</TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.title || "-"}{" "}
                      </TableRowData>
                      <TableRowData style={{ textAlign: "center" }}>
                        {c.created}
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Placeholder />
        )}
      </div>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_withdrawal_request_title}
          text={strings.s_withdrawal_request_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            withdrawWalletPoints();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </div>
  );
}
