/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import SidebarRoutes from "../Parts/sidebarRoutes";
import { StringsContext } from "../DataFactory/useStrings";
import { ReactComponent as UserIcon } from "../assets/images/sidebar/users.svg";

const ParentDiv = styled.div`
  height: calc(100vh - 360px);
  overflow: overlay;

  .pList {
    margin: 0;
  }
  .pListItem {
    font-size: 13px;
    font-weight: 550;
    letter-spacing: 0.2px;
    padding: 6px 15px;
    color: #bbb;
    display: flex;
    margin: 5px 0 0;

    &.active,
    &.active:hover {
      background: #d8ae36;
      border-radius: 5px;
      color: #fff;
      span img {
        opacity: 1;
      }
      .cList {
        background: transparent;
      }
      .pLink {
        color: #fff;
        span img {
          opacity: 1;
        }
      }
      .addIcon {
        color: #fff;
      }
    }
    &:hover {
      background: #3a393e;
      border-radius: 5px;
      .cList {
        background: transparent;
      }
      .pLink {
        color: #fff;
      }
    }
    .pListIcon {
      margin-right: 15px;
      vertical-align: middle;
      img {
        width: 15px;
        height: 15px;
        opacity: 0.7;
      }
    }
    .pLink {
      color: #bbb;
      text-decoration: none;
      display: inline-block;
      width: 90%;
      font-weight: 550;
      letter-spacing: -0.2px;
      padding: 2px 0 0 0;
    }
    .addIcon {
      fill: #fff;
      width: 11px;
      height: 11px;
      padding: 0;
      padding-top: 7px;
      cursor: pointer;
    }

    .review {
      margin: 0;
      color: #d8ae36;
      background: #fff;
      padding: 2px 5px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      height: fit-content;
      float: right;
      font-size: 14px;
      line-height: 18px;

      svg {
        width: 11px;
        height: 11px;
        fill: #d8ae36;
        padding: 0;
        margin-right: 2px;
      }
    }
  }

  .cList {
    background: transparent;
    list-style-type: none;
    padding: 0 0 2px 10px;
    height: 0px;
    display: none;
    opacity: 0;
    margin: 0;
    &.collapse-in {
      display: block;
      opacity: 1;
      height: auto;
      animation-duration: 0.5s;
      animation-name: slideHight;
      transition: height 0.5s;
    }
    &.collapse {
      display: block;
      opacity: 1;
      height: auto;
      animation-duration: 0.3s;
      animation-name: slideMaxHight;
      transition: height 0.3s;
    }
    .cListItem {
      height: auto;
      padding: 0 10px;
      font-size: 14px;
      &.active,
      &:hover {
        background: transparent;
        .cLink {
          color: #fff;
        }
      }
      .cListIcon {
        vertical-align: middle;
        svg {
          margin-right: 10px;
          float: none;
          padding: 0px;
        }
      }
      .cLink {
        color: #b2b2b2;
        display: block;
        padding: 3px 0;
        font-size: 14px;
        font-weight: 450;
      }
    }
  }
`;

function GNestedMenu(props) {
  const [strings] = useContext(StringsContext);
  const location = useLocation();

  function isPermissionExist(per) {
    let exist = props.settings?.permissions?.includes(per);
    console.log(exist);
    return exist;
  }

  function isStoreManager() {
    let uType = props.settings?.user?.super ? true : false;
    return uType;
  }

  return (
    <React.Fragment>
      <ParentDiv
        mode="inline"
        theme="dark"
        // selectedKeys={selectedKeys}
        // openKeys={openKeys}
        // onClick={handleMenuClick}
        // onOpenChange={handleOpenChange}
      >
        <ul className="pList">
          <SidebarRoutes
            settings={props.settings}
            handleClick={(title) => props.handleClick(title)}
          />
          {isStoreManager() && (
            <li className={location.state === "staff" ? "active" : ""}>
              <Link to={{ pathname: "/staff" }} state="staff">
                <span role="img" aria-label="help" className="icon">
                  <UserIcon
                    style={{
                      width: "17px",
                      height: "17px",
                      fill: location.state === "staff" ? "#fff" : "#bbb",
                    }}
                  />
                </span>
                <span>{strings.w_staff_members}</span>
              </Link>
            </li>
          )}
        </ul>
      </ParentDiv>
    </React.Fragment>
  );
}

export default GNestedMenu;
