import React, { useState, useEffect, useRef, useContext } from "react";
import { TableContainer } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  ImgUrl,
  ApiService,
  isStoreManager,
  isPermissionExist,
} from "../services";
import { ReactComponent as Edit } from "../assets/images/icons/edit.svg";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import { ReactComponent as ProductIcon } from "../assets/images/sidebar/products.svg";
import itemPlaceholder from "../assets/images/placeholders/p-item.png";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../Elements/gEmpty";
import GTableShimmer from "../Elements/gTableShimmer";
import GButton from "../Elements/GButton";
import GConfirmDialog from "../Elements/gConfirmDialog";
import GPagination from "../Elements/gPagination";
import { useLocation, useNavigate, Link } from "react-router-dom";
import ProductCreatePopup from "../forms/productCreateForm";
import ProductEditPopup from "../forms/productEditForm";
import QueryWith from "../Query";
import GSelectbox from "../Elements/gSelectbox";
import { GSearch } from "../Elements/gSearch";
import GSwitch from "../Elements/gSwitch";
import useSettings from "../useSettings";
import Modal from "react-modal";
import useDataFactory from "../useDataFactory";
import { StringsContext } from "../DataFactory/useStrings";
import Placeholder from "../Placeholders/Placeholder";
import ProductStatusOff from "../forms/productStatusTurnOffForm";

const statusFilters = [
  {
    title: "Active",
    value: "1",
  },
  {
    title: "Inactive",
    value: "0",
  },
];

export default function Products(props) {
  const [strings] = useContext(StringsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const { data: categoriesData } = useDataFactory("product/group", false);
  const [productsData, setProductsData] = useState([]);
  const [productsPaginationData, setProductsPaginationData] = useState("");
  const [taxClasses, setTaxClasses] = useState([]);
  const [variantTypes, setVariantTypes] = useState([]);
  const [productsLocalizableKeys, setProductsLocalizableKeys] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [selectedProductId, setSelectedProductId] = useState("");
  const [pcvisible, setPcvisible] = useState(false);
  const [pevisible, setPevisible] = useState(false);
  const [pStatusOffVisible, setPStatusOffVisible] = useState(false);
  const settings = useSettings(true);
  let navigate = useNavigate();
  let location = useLocation();
  let params = new URLSearchParams(location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    category: params.get("category") ? params.get("category") : "",
    search: params.get("search") ? params.get("search") : "",
    status: params.get("status") ? params.get("status") : "",
  });

  const columns = [
    { id: "title", label: strings.w_title, minWidth: 130 },
    { id: "category", label: strings.w_category, minWidth: 150 },
    { id: "photo", label: strings.w_photo, minWidth: 50, type: "image" },
    { id: "price", label: strings.w_price, minWidth: 120, align: "center" },
    {
      id: "product_stock",
      label: strings.w_stock,
      minWidth: 90,
      align: "center",
    },
    { id: "status", label: strings.w_status, minWidth: 60 },
    isStoreManager() ||
    isPermissionExist("product_update") ||
    isPermissionExist("product_destroy")
      ? {
          id: "actions",
          label: strings.w_actions,
          minWidth: 60,
          align: "center",
        }
      : "",
  ];

  const getProducts = () => {
    setIsLoading(true);
    var params = QueryWith(location, filters);
    if (params.length > 0) {
      navigate(
        {
          pathname: "/products",
          search: params[0],
        },
        { state: "product" }
      );
    }
    ApiService({ method: "GET", route: "products", body: filters })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setProductsData(response.data.data.data);
          setProductsPaginationData(response.data.data);
          setTaxClasses(response.data?.tax_classes || []);
          setVariantTypes(
            response.data?.variant_types.filter((t) => t.status == 1) || []
          );
          let localKeys = [];
          response.data.localizable_keys.map((key) => {
            localKeys.push({
              title: key.charAt(0).toUpperCase() + key.slice(1),
              value: key,
            });
          });
          setProductsLocalizableKeys(localKeys || []);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProducts();
  }, [filters]);

  function addDefaultLogo(e) {
    e.target.src = itemPlaceholder;
  }

  const deleteProduct = () => {
    ApiService({ method: "DELETE", route: "products/" + selectedProductId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getProducts();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  const handleToggleStatus = (newVal, id, ind) => {
    ApiService({
      method: "PUT",
      route: "products/" + id,
      body: {
        status: newVal,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          productsData[ind].status = newVal;
          setProductsData([...productsData]);
        } else {
          getProducts();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <ProductIcon style={{ fill: "#000" }} /> &nbsp;{strings.w_products}
      </h1>

      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <GSearch
            placeholder={strings.s_search_by_title}
            value={filters.search}
            handleChange={(val) => {
              setFilters({ ...filters, search: val, page: 1 });
            }}
          />
          <GSelectbox
            placeholder={strings.w_select_category}
            defaultvalue={filters.category}
            data={categoriesData}
            id="category_filter"
            option_key="title"
            option_value="id"
            onChange={(newVal) => {
              setFilters({ ...filters, category: newVal, page: 1 });
            }}
            style={{
              border: "1px solid #e2e2e2",
              height: "40px",
              marginRight: 6,
            }}
          />
          <GSelectbox
            placeholder="All"
            defaultvalue={filters.status}
            data={statusFilters}
            id="item_status_filter"
            option_key="title"
            option_value="value"
            onChange={(newVal) => {
              setFilters({ ...filters, status: newVal, page: 1 });
            }}
            style={{
              border: "1px solid #e2e2e2",
              height: "40px",
              minWidth: "150px",
            }}
          />

          {productsPaginationData && (
            <GPagination
              style={{ display: "inline-block", marginLeft: "auto" }}
              count={productsPaginationData.total}
              from={productsPaginationData.from}
              to={productsPaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(productsPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(productsPaginationData.prev_page_url)
              }
              rowsPerPage={productsPaginationData.per_page}
              prevDisabled={productsPaginationData.prev_page_url ? false : true}
              nextDisabled={productsPaginationData.next_page_url ? false : true}
              listStyle={{ margin: "10px 0 0" }}
            ></GPagination>
          )}
          {isStoreManager() || isPermissionExist("product_store") ? (
            <GButton
              variant="condensed"
              children={strings.w_add}
              onClick={() => {
                setPcvisible(true);
              }}
              style={{
                marginTop: "-5px",
                padding: "0.6rem 2rem",
                height: "max-content",
                marginLeft: productsPaginationData ? "0" : "auto",
              }}
            />
          ) : (
            ""
          )}
        </div>

        <div className="listPaper" style={{ marginTop: "15px" }}>
          {isLoading === true ? (
            <GTableShimmer></GTableShimmer>
          ) : (
            <TableContainer>
              {productsData && productsData.length > 0 ? (
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns
                        .filter((c) => c.id)
                        .map((column) =>
                          column.id === "product_stock" &&
                          settings.product_stock === "0" ? (
                            ""
                          ) : (
                            <TableHeader
                              key={column.id}
                              style={{
                                minWidth: column.minWidth,
                                textAlign: column.align,
                              }}
                            >
                              {column.label}
                            </TableHeader>
                          )
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productsData.map((c, i) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={c.id}
                        >
                          <TableRowData
                            style={{
                              BorderBottom: "1px solid #f4f5f9",
                            }}
                          >
                            {c.title}
                          </TableRowData>
                          <TableRowData>{c.categories_title}</TableRowData>
                          <TableRowData>
                            <img
                              alt={c.title}
                              src={ImgUrl("item") + "/" + c.thumb_photo}
                              onError={addDefaultLogo}
                              style={{
                                height: "45px",
                                width: "45px",
                                borderRadius: "50%",
                              }}
                            />{" "}
                          </TableRowData>
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {c.price ? settings.currency_symbol + c.price : "-"}
                          </TableRowData>
                          {settings.product_stock == "1" ? (
                            <TableRowData
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {c.unlimited === 1
                                ? "∞"
                                : c.stock
                                ? c.stock
                                : "-"}
                            </TableRowData>
                          ) : (
                            ""
                          )}
                          <TableRowData>
                            <GSwitch
                              id={"item" + c.id}
                              uId={c.id}
                              currentValue={
                                c.status === "true" ||
                                c.status === "1" ||
                                c.status === 1
                                  ? 1
                                  : 0
                              }
                              disabled={
                                isStoreManager() ||
                                isPermissionExist("product_update")
                                  ? false
                                  : true
                              }
                              onSwitchChange={(newVal, id) => {
                                console.log(newVal);
                                if (newVal == 0) {
                                  setSelectedProductData(c);
                                  setPStatusOffVisible(true);
                                } else {
                                  handleToggleStatus(newVal, id, i);
                                }
                              }}
                            />
                          </TableRowData>
                          {isStoreManager() ||
                          isPermissionExist("product_update") ||
                          isPermissionExist("product_destroy") ? (
                            <TableRowData
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {isStoreManager() ||
                              isPermissionExist("product_update") ? (
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setSelectedProductData(c);
                                    setPevisible(true);
                                  }}
                                >
                                  <Edit
                                    fontSize="small"
                                    style={{ width: "18px" }}
                                  />
                                </GButton>
                              ) : (
                                ""
                              )}
                              {isStoreManager() ||
                              isPermissionExist("product_destroy") ? (
                                <GButton
                                  variant="linkable"
                                  onClick={(e) => {
                                    setSelectedProductId(c.id);
                                    setDialogOpen(true);
                                  }}
                                >
                                  <Delete
                                    fontSize="small"
                                    style={{ width: "18px" }}
                                  />
                                </GButton>
                              ) : (
                                ""
                              )}
                            </TableRowData>
                          ) : (
                            ""
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <Placeholder type={"product"} />
              )}
            </TableContainer>
          )}
        </div>
      </React.Fragment>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_product_title}
          text={strings.s_delete_product_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteProduct();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={pcvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setPcvisible(false)}
        style={{
          content: {
            width: "650px",
          },
        }}
        contentLabel="Product Create Form Modal"
      >
        <ProductCreatePopup
          pcvisible={pcvisible}
          setPcvisible={setPcvisible}
          localizableKeys={productsLocalizableKeys}
          onSubmit={getProducts}
          settings={settings}
          categoriesData={categoriesData || []}
          taxClasses={taxClasses || []}
          variantTypes={variantTypes || []}
        />
      </Modal>

      <Modal
        isOpen={pevisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setPevisible(false)}
        style={{
          content: {
            width: "650px",
          },
        }}
        contentLabel="Product Edit Form Modal"
      >
        <ProductEditPopup
          pevisible={pevisible}
          setPevisible={setPevisible}
          localizableKeys={productsLocalizableKeys}
          data={selectedProductData}
          onSubmit={getProducts}
          settings={settings}
          categoriesData={categoriesData || []}
          taxClasses={taxClasses || []}
          variantTypes={variantTypes || []}
          onSubmitImg={(img) => {
            console.log(img);
            let itemIndex = productsData.findIndex(
              (nId) => nId?.id == selectedProductData.id
            );
            console.log(itemIndex);
            if (itemIndex > -1) {
              productsData[itemIndex].photo = img.photo;
              productsData[itemIndex].thumb_photo = img.thumb_photo;
              setProductsData([...productsData]);
            }
          }}
          onRemoveImg={() => {
            let itemIndex = productsData.findIndex(
              (nId) => nId?.id == selectedProductData.id
            );
            if (itemIndex > -1) {
              productsData[itemIndex].photo = "";
              productsData[itemIndex].thumb_photo = "";
              setProductsData([...productsData]);
            }
          }}
          onLocalizationRemove={(ind) => {
            let findInd = productsData.findIndex(
              (nId) => nId?.id == selectedProductData.id
            );

            if (findInd > -1) {
              productsData[findInd].localizations.splice(ind, 1);
            }
          }}
          onLocalizationUpdate={(ind, data) => {
            let findInd = productsData.findIndex(
              (nId) => nId?.id == selectedProductData.id
            );

            if (findInd > -1) {
              productsData[findInd].localizations[ind] = data;
            }
          }}
        />
      </Modal>

      <Modal
        isOpen={pStatusOffVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setPStatusOffVisible(false)}
        style={{
          content: {
            width: "500px",
          },
        }}
        contentLabel="Product Status Turn off Form Modal"
      >
        <ProductStatusOff
          pStatusOffVisible={pStatusOffVisible}
          setPStatusOffVisible={setPStatusOffVisible}
          data={selectedProductData}
          settings={settings}
          onSubmit={() => getProducts()}
        />
      </Modal>
    </div>
  );
}
