import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import GSwitch from "../Elements/gSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useLocation } from "react-router-dom";
import GButton from "../Elements/GButton";
import moment from "moment-timezone";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import { ProductFilter } from "../Components/productFilterComponent";
import GText from "../Elements/GText";
import GDateTimePicker from "../Elements/gDateTimePicker";

export default function PromotionForm(props) {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const location = useLocation();

  const initialValues = {
    title: !props.isEmpty ? props.data?.title : "",
    description: !props.isEmpty ? props.data.description : "",
    status: !props.isEmpty ? props.data.status : 1,
    code: !props.isEmpty ? props.data.code : "",
    targeted_customers: !props.isEmpty
      ? props.data?.targeted_customers || "all"
      : "all",
    promotion_type: !props.isEmpty ? props.data?.promotion_type : "",
    fixed_discount: !props.isEmpty ? props.data?.fixed_discount || "" : "",
    percentage_discount: !props.isEmpty
      ? props.data?.percentage_discount || ""
      : "",
    free_product: !props.isEmpty ? props.data.free_product || "" : "",
    free_product_count: !props.isEmpty
      ? props.data.free_product_count || ""
      : "",
    promo_product: !props.isEmpty ? props.data.promo_product || "" : "",
    promo_product_min_count: props.data
      ? props.data.promo_product_min_count || ""
      : "",
    valid_from: !props.isEmpty ? props.data?.valid_from || "" : "",
    valid_upto: !props.isEmpty ? props.data?.valid_upto || "" : "",
    limit_total: !props.isEmpty ? props.data?.limit_total || "" : "",
    limit_user: !props.isEmpty ? props.data?.limit_user || "" : "",
    max_discount: !props.isEmpty ? props.data?.max_discount || "" : "",
    minimum_order_amount: !props.isEmpty
      ? props.data?.minimum_order_amount || ""
      : "",
    maximum_order_amount: !props.isEmpty
      ? props.data?.maximum_order_amount || ""
      : "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);

    let sendData = { ...values };
    sendData.code = sendData.code || "";
    sendData.valid_from = sendData.valid_from
      ? moment(sendData.valid_from).format("yyyy-MM-DD")
      : "";
    sendData.valid_upto = sendData.valid_upto
      ? moment(sendData.valid_upto).format("yyyy-MM-DD")
      : "";
    sendData.limit_total = sendData.limit_total || "0";
    sendData.limit_user = sendData.limit_user || "0";
    sendData.max_discount = sendData.max_discount || "0";
    sendData.minimum_order_amount = sendData.minimum_order_amount || "0";
    sendData.maximum_order_amount = sendData.maximum_order_amount || "0";

    console.log(sendData);

    // return;
    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "coupons/" + props.data.id : "coupons";
    ApiService({ method: hitMethod, route: hitUrl, body: sendData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setCcpnvisible(false);
          if (props.data) {
            props.onSubmit(response.data.data);
          }
          if (props.isEmpty) {
            if (location.pathname === "/coupons") {
              window.location.reload(false);
            } else {
              window.location = "/coupons";
            }
          }
        }
        setIsBtnDisabled(false);
        enqueueSnackbar(response.data.message);
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    targeted_customers: Yup.string().required(strings.w_required),
    promotion_type: Yup.string().required(strings.w_required),
  });

  if (props.ccpnvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit_promotion : strings.w_add_promotion}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input-md"
                type="text"
                label={strings.w_title}
                name="title"
                placeholder={strings.s_get_discount_upto}
              />

              <FormikControl
                control="textarea-md"
                as="textarea"
                label={strings.w_description}
                name="description"
                rows="3"
                placeholder={strings.s_use_code_and_get_discount}
              />

              <div
                style={{
                  display: "inline-block",
                  width: "40%",
                  boxSizing: "border-box",
                  verticleAlign: "top",
                  padding: "4px 15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "30px",
                    border: "1px solid #e2e2e2",
                    borderRadius: "4px",
                    height: "43px",
                    padding: "4px 20px 5px",
                  }}
                >
                  <label style={{ margin: "0" }}>{strings.w_status}</label>
                  <FormikControl
                    control="toggle"
                    isToggled={values.status == "1" ? 1 : 0}
                    name="status"
                    onToggle={() => {
                      let statusVal = values.status === "1" ? true : false;
                      statusVal = !statusVal;
                      statusVal = statusVal === true ? "1" : "0";
                      setFieldValue("status", statusVal);
                    }}
                    style={{ padding: 0 }}
                  />
                </div>
              </div>

              <div style={{ padding: "12px 15px" }}>
                <GText g4 semi text={strings.w_targeted_customers} />

                <div
                  style={{
                    paddingTop: "12px",
                  }}
                >
                  <FormikControl
                    control="radio-md"
                    name="targeted_customers"
                    options={[
                      { title: strings.w_all, value: "all" },
                      { title: strings.w_new, value: "new" },
                      { title: strings.w_lapsed, value: "lapsed" },
                    ]}
                    key_title="title"
                    key_value="value"
                    mainDivStyle={{ padding: 0, margin: 0, width: "100%" }}
                    onChange={(value) => {
                      setFieldValue("targeted_customers", value || "");
                    }}
                  />
                </div>
              </div>

              <div style={{ padding: "15px" }}>
                <GText g4 semi text={strings.w_promotion_reward} />

                <div
                  style={{
                    padding: "12px 0 8px",
                  }}
                >
                  <FormikControl
                    control="radio-md"
                    name="promotion_type"
                    options={[
                      {
                        title: "% " + strings.w_discount,
                        value: "percentage_discount",
                      },
                      {
                        title: strings.w_fixed_discount,
                        value: "fixed_discount",
                      },
                      {
                        title: strings.w_free_delivery,
                        value: "free_delivery",
                      },
                      {
                        title: strings.w_free_product,
                        value: "free_product",
                      },
                    ]}
                    key_title="title"
                    key_value="value"
                    mainDivStyle={{ padding: 0, margin: 0, width: "100%" }}
                    onChange={(value) => {
                      setFieldValue("promotion_type", value || "");
                      setFieldValue("percentage_discount", "");
                      setFieldValue("fixed_discount", "");
                      setFieldValue("free_product", "");
                      setFieldValue("free_product_count", "");
                    }}
                  />
                </div>

                {values.promotion_type === "fixed_discount" && (
                  <FormikControl
                    control="input-md"
                    type="text"
                    label={strings.w_fixed_discount}
                    name="fixed_discount"
                    inputRightSymbol={settings.currency_symbol}
                    maxLength={3}
                    placeholder="15"
                    onChange={(event) => {
                      const re = /^[0-9.\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("fixed_discount", event.target.value);
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "30%",
                      boxSizing: "border-box",
                      margin: "0",
                      padding: "8px 4px",
                    }}
                  />
                )}

                {values.promotion_type === "percentage_discount" && (
                  <FormikControl
                    control="input-md"
                    type="text"
                    label={`% ${strings.w_discount}`}
                    inputRightSymbol={"%"}
                    name="percentage_discount"
                    maxLength={3}
                    placeholder="15"
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue(
                          "percentage_discount",
                          event.target.value || ""
                        );
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "30%",
                      boxSizing: "border-box",
                      margin: "0",
                      padding: "8px 4px",
                    }}
                  />
                )}

                {values.promotion_type === "free_product" && (
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      alignItems: "start",
                      gap: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        width: "35%",
                      }}
                    >
                      <div
                        style={{
                          margin: "0",
                          border: "1px solid #e2e2e2",
                          borderRadius: "4px",
                          padding: "4px 10px",
                        }}
                      >
                        <label
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: "12px",
                            color: "#aaa",
                          }}
                        >
                          {strings.w_free_product}
                        </label>
                        <ProductFilter
                          value={values.free_product}
                          placeholder={strings.w_choose}
                          productTitle={
                            props.data?.free_product_display?.title || ""
                          }
                          style={{
                            margin: "0px",
                            width: "100%",
                            padding: "2px 0",
                          }}
                          status={1}
                          containerStyle={{ margin: "0", width: "100%" }}
                          innerStyle={{
                            border: 0,
                            padding: 0,
                            height: "25px",
                          }}
                          isStored={values.free_product ? true : false}
                          perPage={50}
                          handleProductChange={(id, value) => {
                            setFieldValue("free_product", id ? id : "");
                            if (!id) {
                              setFieldValue("free_product_count", "");
                            }
                          }}
                        />
                      </div>

                      <FormikControl
                        control="input"
                        type="text"
                        name="free_product"
                        divstyle={{ padding: 0, margin: 0 }}
                        style={{ display: "none" }}
                      />
                    </div>

                    {values.free_product && (
                      <FormikControl
                        control="input-md"
                        type="text"
                        label={strings.w_free_product_count}
                        name="free_product_count"
                        maxLength={2}
                        placeholder="15"
                        onChange={(event) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            event.target.value &&
                            !re.test(event.target.value)
                          ) {
                            return;
                          } else {
                            setFieldValue(
                              "free_product_count",
                              event.target.value
                            );
                          }
                        }}
                        divstyle={{
                          padding: 0,
                          width: "40%",
                          margin: 0,
                          display: "inline-block",
                        }}
                      />
                    )}
                  </div>
                )}
              </div>

              <div style={{ padding: "15px" }}>
                <GText g4 semi text={strings.w_promotion_requirements} />

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px 27px",
                    paddingTop: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      position: "relative",
                      width: "35%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <GDateTimePicker
                      label={strings.w_valid_from}
                      value={values.valid_from}
                      placeholderText={strings.w_choose}
                      timezone={"current"}
                      isDateOnly={true}
                      onChange={(v) => setFieldValue("valid_from", v || "")}
                      mainDivStyle={{ borderRadius: "4px", minHeight: "40px" }}
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      name="valid_from"
                      style={{ display: "none" }}
                      divstyle={{ margin: 0, padding: 0 }}
                    />
                  </div>
                  {/* <FormikControl
                    control="date-md"
                    label={strings.w_valid_from}
                    name="valid_from"
                    format="yyyy-MM-dd"
                    placeholderText={strings.w_choose}
                    divstyle={{
                      display: "inline-block",
                      position: "relative",
                      width: "35%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  /> */}

                  <div
                    style={{
                      display: "inline-block",
                      position: "relative",
                      width: "35%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <GDateTimePicker
                      label={strings.w_valid_upto}
                      value={values.valid_upto}
                      placeholderText={strings.w_choose}
                      timezone={"current"}
                      isDateOnly={true}
                      onChange={(v) => setFieldValue("valid_upto", v || "")}
                      mainDivStyle={{ borderRadius: "4px", minHeight: "40px" }}
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      name="valid_upto"
                      style={{ display: "none" }}
                      divstyle={{ margin: 0, padding: 0 }}
                    />
                  </div>
                  {/* <FormikControl
                    control="date-md"
                    label={strings.w_valid_upto}
                    name="valid_upto"
                    format="yyyy-MM-dd"
                    placeholderText={strings.w_choose}
                    divstyle={{
                      display: "inline-block",
                      position: "relative",
                      width: "35%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  /> */}

                  <FormikControl
                    control="input-md"
                    type="text"
                    label={strings.w_min_order}
                    name="minimum_order_amount"
                    placeholder="500"
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue(
                          "minimum_order_amount",
                          event.target.value
                        );
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "35%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  />

                  <FormikControl
                    control="input-md"
                    type="text"
                    label={strings.w_max_order}
                    name="maximum_order_amount"
                    placeholder="500"
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue(
                          "maximum_order_amount",
                          event.target.value
                        );
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "35%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  />
                </div>
              </div>

              <div style={{ padding: "10px 15px" }}>
                <GText g4 semi text={strings.w_cart_requirements} />

                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    alignItems: "start",
                    gap: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      width: "35%",
                    }}
                  >
                    <div
                      style={{
                        margin: "0",
                        border: "1px solid #e2e2e2",
                        borderRadius: "4px",
                        padding: "4px 10px",
                      }}
                    >
                      <label
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: "12px",
                          color: "#aaa",
                        }}
                      >
                        {strings.w_required_product}
                      </label>
                      <ProductFilter
                        value={values.promo_product}
                        placeholder={strings.w_choose}
                        productTitle={
                          props.data?.promo_product_display?.title || ""
                        }
                        style={{
                          margin: "0px",
                          width: "100%",
                          padding: "2px 0",
                        }}
                        status={1}
                        containerStyle={{ margin: "0", width: "100%" }}
                        innerStyle={{
                          border: 0,
                          padding: 0,
                          height: "25px",
                        }}
                        isStored={values.promo_product ? true : false}
                        perPage={50}
                        handleProductChange={(id, value) => {
                          setFieldValue("promo_product", id ? id : "");
                          if (!id) {
                            setFieldValue("promo_product_min_count", "");
                          }
                        }}
                      />
                    </div>

                    <FormikControl
                      control="input"
                      type="text"
                      name="promo_product"
                      divstyle={{ padding: 0, margin: 0 }}
                      style={{ display: "none" }}
                    />
                  </div>

                  {values.promo_product && (
                    <FormikControl
                      control="input-md"
                      type="text"
                      label={strings.w_required_product_count}
                      name="promo_product_min_count"
                      maxLength={2}
                      placeholder="15"
                      onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue(
                            "promo_product_min_count",
                            event.target.value
                          );
                        }
                      }}
                      divstyle={{
                        padding: 0,
                        width: "35%",
                        margin: 0,
                        display: "inline-block",
                      }}
                    />
                  )}
                </div>
              </div>

              <div style={{ padding: "15px" }}>
                <GText g4 semi text={strings.w_promotion_limits} />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px 26px",
                    paddingTop: "8px",
                  }}
                >
                  <FormikControl
                    control="input-md"
                    type="text"
                    label={strings.w_total_usage_limit}
                    name="limit_total"
                    placeholder="500"
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("limit_total", event.target.value);
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "30%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  />

                  <FormikControl
                    control="input-md"
                    type="text"
                    label={strings.w_user_limit}
                    name="limit_user"
                    placeholder="500"
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("limit_user", event.target.value);
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "30%",
                      boxSizing: "border-box",
                      margin: 0,
                      padding: 0,
                    }}
                  />
                  {values.promotion_type === "percentage_discount" && (
                    <FormikControl
                      control="input-md"
                      type="text"
                      label={strings.w_max_discount}
                      name="max_discount"
                      placeholder="100"
                      onChange={(event) => {
                        const re = /^[0-9\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("max_discount", event.target.value);
                        }
                      }}
                      divstyle={{
                        display: "inline-block",
                        width: "30%",
                        boxSizing: "border-box",
                        margin: 0,
                        padding: 0,
                      }}
                    />
                  )}
                </div>
              </div>

              <GButton
                disabled={isBtnDisabled}
                type="submit"
                variant="condensed"
                children={props.data ? strings.w_update : strings.w_create}
                style={{ width: "calc(100% - 30px)", margin: "10px 15px 0" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
