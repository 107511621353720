import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";

function selectGroupbox(props) {
  const { label, name, key_title, key_value, options, ...rest } = props;
  return (
    <div className="form-control" style={props.divstyle}>
      <label htmlFor={name}>{label}</label>
      <Field as="select" id={name} name={name} {...rest}>
        <option value="">Select {props.label}</option>
        {options.map((option, ind) => {
          return (
            <optgroup label={option[key_title]} key={ind}>
              {option[props.subOptions].map((o, i) => {
                return (
                  <option key={i} value={o[key_value]}>
                    {o[key_title]}
                  </option>
                );
              })}
            </optgroup>
          );
        })}
      </Field>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default selectGroupbox;
