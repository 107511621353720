import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import GDateTimePicker from "../Elements/gDateTimePicker";
import { StringsContext } from "../DataFactory/useStrings";

export default function TimeslotsForm(props) {
  const [strings] = useContext(StringsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    starts: props.data ? props.data.starts : "",
    ends: props.data ? props.data.ends : "",
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "holiday_hours/" + props.data.id
      : "holiday_hours";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setWhvisible(false);
          props.onSubmit(response.data.data);
        }
        setIsDisabled(false);
        enqueueSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    starts: Yup.string().required(strings.w_required),
    ends: Yup.string().required(strings.w_required),
  });

  if (props.whvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty
          ? strings.s_edit_holiday_hour
          : strings.s_add_holiday_hour}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div style={{ position: "relative", padding: "10px 15px" }}>
                <label>{strings.w_start_time}</label>
                <GDateTimePicker
                  value={values.starts}
                  timezone={"current"}
                  onChange={(v) => setFieldValue("starts", v || "")}
                />
                <FormikControl
                  control="input"
                  type="text"
                  name="starts"
                  style={{ display: "none" }}
                  divstyle={{ margin: 0, padding: 0 }}
                />
              </div>

              <div style={{ position: "relative", padding: "10px 15px" }}>
                <label>{strings.w_end_time}</label>
                <GDateTimePicker
                  value={values.ends}
                  timezone={"current"}
                  onChange={(v) => setFieldValue("ends", v || "")}
                />
                <FormikControl
                  control="input"
                  type="text"
                  name="ends"
                  style={{ display: "none" }}
                  divstyle={{ margin: 0, padding: 0 }}
                />
              </div>
              <br />
              <GButton
                type="submit"
                disabled={isDisabled}
                variant="condensed"
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
