import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import { Table, TableBody, TableRowData } from "../styledComponents/tableStyle";
import { ReactComponent as Add } from "../assets/images/icons/add.svg";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import { ReactComponent as Remove } from "../assets/images/icons/delete.svg";
import { ReactComponent as CheckIcon } from "../assets/images/check.svg";
import GInput from "./gInput";
import GButton from "./GButton";
const Small = styled.small`
  font-size: 0.8rem;
  font-weight: 400;
  display: inline-block;
  padding-right: 5px;
  /* display: block; */
`;

const Tr = styled.tr`
  td {
    border-bottom: 1px solid #f2f2f2;
  }
`;

function GItemTable(props) {
  const [eitems, setEItems] = useState([...props.items] || []);
  const [editableQuant, setEditableQuant] = useState();

  useEffect(() => {
    console.log(props.items);
    props.items.map((item) => {
      item.editable_view = false;
    });
    console.log(props.items);
    setEItems([...props.items]);
  }, [props.items]);



  return (
    <Table className="table">
      <TableBody>
        {eitems.map((i, ind) => {
          return (
            <Tr key={i.id}>
              <td style={{ width: "80px" }}>
                <img
                  alt=""
                  src={ImgUrl("item") + "/" + i.thumb_photo}
                  onError={(e) => addDefaultSrc(e, "item")}
                  className="thumbnail"
                  style={{ width: "50px", height: "50px", objectFit: "cover" }}
                />
              </td>
              <TableRowData>
                {i.title}
                <br />
                {(i.variants_available === true || i.variants) &&
                  i.variants.map((v, $index) => {
                    return (
                      <Small key={v.id}>
                        {v.title}
                        {v.value ? (
                          <span style={{ color: "#777" }}> : {v.value}</span>
                        ) : (
                          ""
                        )}
                        {i.variants.length > $index + 1 ? <span>, </span> : ""}
                      </Small>
                    );
                  })}
              </TableRowData>
              <TableRowData style={{ minWidth: "80px" }}>
                {props.currencySymbol}
                {i.price_display ? i.price_display : i.price} * {i.quantity}
              </TableRowData>

               <TableRowData style={{ textAlign: "right" }}>
                {props.currencySymbol}
                {(parseFloat(i.price) * i.quantity).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}
              </TableRowData>

            </Tr>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default GItemTable;
