import React from "react";
import styled from "styled-components";

const Text = styled.p((props) => [
  {
    whiteSpace: "pre-line",
    fontSize: fontSize(props.props) + "px",
    lineHeight: LineHeight(props.props) + "px",
    color: color(props.props),
    fontWeight: fontWeight(props.props),
    margin: 0,
  },
  props.style,
]);

const GText = (props) => {
  const { text, style } = props;
  return (
    <Text {...props} props={props}>
      {text}
    </Text>
  );
};

const fontSize = (props) => {
  if (props.g1) {
    return 28;
  } else if (props.g2) {
    return 24;
  } else if (props.g3) {
    return 21;
  } else if (props.g4) {
    return 18;
  } else if (props.g5) {
    return 16;
  } else if (props.g6) {
    return 13;
  }
  return 15;
};

const LineHeight = (props) => {
  if (props.g1) {
    return 35;
  } else if (props.g2) {
    return 31;
  } else if (props.g3) {
    return 28;
  } else if (props.g4) {
    return 25;
  } else if (props.g5) {
    return 22;
  } else if (props.g6) {
    return 19;
  }
  return 21;
};

const fontWeight = (props) => {
  if (props.bold) {
    return "700";
  } else if (props.semib) {
    return "600";
  } else if (props.semi) {
    return "500";
    // } else if (props.italic) {
    //   return "OpenSans-Italic";
  } else return "400";
};

const italic = (props) => {
  if (props.italic) {
    return true;
  }
  return false;
};

const color = (props) => {
  if (props.theme) {
    return "#d8ae36";
  }
  if (props.light) {
    return "#cccccc";
  }
  if (props.med) {
    return "#999";
  }
  if (props.dark) {
    return "#555";
  }
  return "#000";
};

export default GText;
