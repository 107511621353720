import React, { useContext, useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import { StringsContext } from "../DataFactory/useStrings";

function ProductStatusOff(props) {
  const [strings] = useContext(StringsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const turnOffReasons = [
    { title: strings.w_out_of_stock, value: "out_of_stock" },
    { title: strings.w_other, value: "other" },
  ];

  const initialValues = {
    product_id: props.data.id,
    reason: "",
    other_reason: "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(false);
    console.log(values);
    if (values.reason === "other") {
      let sendData = { ...values };
      sendData.reason = sendData.other_reason;
      ApiService({
        method: "PUT",
        route: "product/availability",
        body: sendData,
      }).then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setPStatusOffVisible(false);
          props.onSubmit();
        }
        setIsBtnDisabled(false);
        enqueueSnackbar(response.data.message);
      });
    } else {
      let sendData = {
        unlimited: 0,
        stock: 0,
        status: 1,
      };
      ApiService({
        method: "PUT",
        route: "products/" + props.data.id,
        body: sendData,
      }).then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setPStatusOffVisible(false);
          props.onSubmit();
        }
        setIsBtnDisabled(false);
        enqueueSnackbar(response.data?.message);
      });
    }
  };

  const validationSchema = Yup.object({
    reason: Yup.string().required(strings.w_required),
    other_reason: Yup.string().when("reason", {
      is: (val) => val == "other",
      then: () => Yup.string().required(strings.w_required),
    }),
  });

  if (props.pStatusOffVisible === false) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setPStatusOffVisible(false)}
        />
        {strings.s_turn_off_product}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <label style={{ margin: "5px 15px" }}>
                {strings.s_please_select_reason_for_turn_off_products}
              </label>
              <FormikControl
                control="radio"
                name="reason"
                options={turnOffReasons || []}
                key_title="title"
                custom={true}
                key_value="value"
                divstyle={{ display: "block", padding: "5px 0" }}
              />
              {values.reason === "other" ? (
                <FormikControl
                  control="input"
                  as="textarea"
                  placeholder={strings.s_type_reason_here}
                  name="other_reason"
                />
              ) : (
                ""
              )}

              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={strings.w_submit}
                style={{ width: "calc(100% - 30px)", margin: "20px 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}

export default ProductStatusOff;
