import React, { useState, useEffect, useContext } from "react";
import { TableContainer } from "@mui/material";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GPagination from "../Elements/gPagination";
import GPaginationSelect from "../Elements/gPaginationSelectbox";
import { Link, useLocation, useNavigate } from "react-router-dom";
import QueryWith from "../Query";
import RatingStars from "../Elements/gStarRating";
import useDataFctory from "../useDataFactory";
import GText from "../Elements/GText";
import { StringsContext } from "../DataFactory/useStrings";

export default function ReviewsList() {
  const [strings] = useContext(StringsContext);
  let navigate = useNavigate();
  let location = useLocation();
  let params = new URLSearchParams(location.search);

  const columns = [
    { id: "order_id", label: strings.w_order, minWidth: 60 },
    { id: "customer", label: strings.w_customer, minWidth: 120 },
    { id: "review", label: strings.w_review, minWidth: 160 },
    { id: "rating", label: strings.w_rating, minWidth: 100 },
    {
      id: "created_at_formatted",
      label: strings.w_created,
      minWidth: 120,
      align: "center",
    },
  ];

  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    published: params.get("published") ? params.get("published") : "",
  });

  const {
    loading,
    TableShimmer,
    Placeholder,
    data: reviewsData,
    loadMore,
    pagination: reviewsPaginationData,
    refreshData,
  } = useDataFctory("reviews", true, filters);

  useEffect(() => {
    var params = QueryWith(location, filters);
    if (params.length > 0) {
      navigate(
        {
          pathname: "/reviews",
          search: params[0],
        },
        {
          state: "review",
        }
      );
    }
    refreshData({ ...filters });
  }, [filters]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  return (
    <div className="mainInnerView">
      <GText g1="true" bold text={strings.w_reviews} />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "10px",
          margin: "10px 0",
        }}
      >
        {reviewsPaginationData && (
          <React.Fragment>
            <GPagination
              count={reviewsPaginationData.total}
              from={reviewsPaginationData.from}
              to={reviewsPaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(reviewsPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(reviewsPaginationData.prev_page_url)
              }
              rowsPerPage={reviewsPaginationData.per_page}
              prevDisabled={reviewsPaginationData.prev_page_url ? false : true}
              nextDisabled={reviewsPaginationData.next_page_url ? false : true}
              style={{ marginLeft: "auto" }}
            ></GPagination>
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={reviewsPaginationData.last_page}
              onChangePage={(NewPageNo) =>
                setFilters({ ...filters, page: NewPageNo })
              }
            />
          </React.Fragment>
        )}
      </div>

      <div className="listPaper" style={{ marginTop: "12px" }}>
        {loading === true ? (
          <TableShimmer></TableShimmer>
        ) : (
          <TableContainer>
            {reviewsData && reviewsData.length > 0 ? (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviewsData.map((c, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData>
                          <Link
                            to={{
                              pathname: "/order/" + c.order_id,
                              state: "order",
                            }}
                          >
                            #{c.order_id}
                          </Link>
                        </TableRowData>
                        <TableRowData>{c.customer.title}</TableRowData>
                        <TableRowData>{c.review || "-"}</TableRowData>
                        <TableRowData>
                          <RatingStars
                            totalStars={5}
                            selectedStars={c.rating}
                          />
                        </TableRowData>

                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.created}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Placeholder />
            )}
          </TableContainer>
        )}
      </div>
    </div>
  );
}
