import React, { useState, useEffect, useContext } from "react";
import { SettingsContext } from "../DataFactory/useSettings";
import { ApiService, ImgUrl } from "../services";
import { GImagePicker } from "../Elements/gImagePicker";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import GTableShimmer from "../Elements/gTableShimmer";
import { StringsContext } from "../DataFactory/useStrings";

export default function StoreDetails() {
  const [strings] = useContext(StringsContext);
  const { settings, setSettings } = useContext(SettingsContext);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [icon, setIcon] = useState("");

  const salesReportValues = [
    { title: strings.w_daily, value: "daily" },
    { title: strings.w_weekly, value: "weekly" },
    { title: strings.w_monthly, value: "monthly" },
  ];

  const initialValues = {
    title: settings?.store?.title || "",
    phone: settings?.store?.phone || "",
    photo: settings?.store?.photo || "",
    thumb_photo: settings?.store?.thumb_photo || "",
    sales_report: settings?.store?.sales_report || "",
    pack_time: settings?.store?.pack_time || "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    console.log(values);

    ApiService({
      method: "PUT",
      route: "stores/" + settings?.store?.id,
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        // settings.store = response.data.data;
        setSettings({ ...settings, store: response.data.data });
      }
      setBtnLoading(false);
      enqueueSnackbar(response.data.message);
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    phone: Yup.string().required(strings.w_required),
  });

  return (
    <div className={"mainInnerView"}>
      <h1>{strings.w_store_details} </h1>
      <div className="listPaper" style={{ marginTop: "0" }}>
        {settings?.store?.title ? (
          <div style={{ width: "60%", minWidth: "530px", maxWidth: "700px" }}>
            <Formik
              initialValues={settings?.store}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "160px",
                        margin: "0 auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <GImagePicker
                        type="store"
                        alt={settings.store?.title}
                        height={100}
                        width={100}
                        radius={50}
                        editor
                        editorProps={{
                          height: 200,
                          width: 200,
                          radius: 100,
                        }}
                        serverProps={{
                          size: 200,
                        }}
                        onSuccess={(v) => {
                          console.log(v);
                          setIcon(v.thumb_image);
                          values.photo = v.image;
                          values.thumb_photo = v.thumb_image;
                        }}
                        isPreviewImg={true}
                        default={
                          ImgUrl("store") + "/" + settings.store?.thumb_photo
                        }
                      />
                    </div>

                    <div style={{ width: "calc(100% - 160px)" }}>
                      <FormikControl
                        control="input"
                        type="text"
                        label={strings.w_store_title}
                        disabled={isBtnLoading}
                        name="title"
                        onChange={(event) => {
                          const re = /^[A-Za-z \b]+$/;
                          if (
                            event.target.value &&
                            !re.test(event.target.value)
                          ) {
                            return;
                          } else {
                            setFieldValue("title", event.target.value);
                          }
                        }}
                      />
                      <FormikControl
                        control="input"
                        type="text"
                        disabled={isBtnLoading}
                        label={strings.w_phone}
                        name="phone"
                        onChange={(event) => {
                          const re = /^[0-9+\b]+$/;
                          if (
                            event.target.value &&
                            !re.test(event.target.value)
                          ) {
                            return;
                          } else {
                            setFieldValue("phone", event.target.value);
                          }
                        }}
                      />

                      <FormikControl
                        control="input"
                        type="text"
                        disabled={isBtnLoading}
                        label={strings.s_packaging_time_in_min}
                        name="pack_time"
                        maxLength={4}
                        onChange={(event) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            event.target.value &&
                            !re.test(event.target.value)
                          ) {
                            return;
                          } else {
                            setFieldValue("pack_time", event.target.value);
                          }
                        }}
                        divstyle={{
                          display: "inline-block",
                          verticalAlign: "top",
                          boxSizing: "border-box",
                          width: "50%",
                        }}
                      />

                      <FormikControl
                        control="select"
                        as="select"
                        disabled={isBtnLoading}
                        label={strings.w_sales_report}
                        name="sales_report"
                        placeholder={"Choose"}
                        options={salesReportValues}
                        key_title="title"
                        key_value="value"
                        divstyle={{
                          display: "inline-block",
                          verticalAlign: "top",
                          boxSizing: "border-box",
                          width: "50%",
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <GButton
                      type="submit"
                      disabled={isBtnLoading}
                      variant="condensed"
                      children={strings.w_update}
                      style={{
                        margin: "15px",
                        width: "auto",
                        marginLeft: "auto",
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <GTableShimmer />
        )}
      </div>
    </div>
  );
}
