import React from "react";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import { I18nManager } from "react-native";

const GBackIcon = (props) => {
  return (
    <ArrowBackIos
      className="backIcon"
      onClick={props.onClick}
      style={{
        transform: I18nManager.getConstants().isRTL
          ? "rotate(180deg)"
          : "rotate(0deg)",
      }}
    />
  );
};

export default GBackIcon;
