import React, { useContext } from "react";
import styled from "styled-components";
import GText from "./GText";
import { StringsContext } from "../DataFactory/useStrings";

const Ul = styled.ul`
  display: flex;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0;
  list-style-type: none;
`;
const Li = styled.li`
  padding: 15px 10px;
  text-align: left;
  font-size: 13.5px;
  position: relative;
  display: block;
  float: left;
  cursor: pointer;
  p {
    margin: 5px 0;
  }
`;

export default function OrderStatuses(props) {
  const [strings] = useContext(StringsContext);
  function handleClick(iden) {
    console.info("You clicked a breadcrumb.");
    props.onClickStatus(iden);
  }

  return (
    <div>
      <Ul>
        {props.statusList.map((status) => {
          return (
            <Li
              onClick={() => {
                handleClick(status.identifier);
              }}
              key={status.identifier}
              style={{
                color: "#" + status.color1,
                background: "#" + status.color2,
                borderBottom:
                  props.defaultStatus === status.identifier
                    ? "4px solid #" + status.color1
                    : "",
                width: "18%",
                textAlign: "center",
              }}
              className={(props.class, status.color)}
            >
              <GText
                text={status.title + " " + strings.w_orders}
                style={{ color: "#" + status.color1, fontWeight: "550" }}
              />
            </Li>
          );
        })}
      </Ul>
    </div>
  );
}
