import React, { useEffect, useState, useContext } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import ProductImages from "../Pages/productImages";
import GTabs from "../Elements/gTabs";
import ProductVariants from "../Pages/productVariants";
import GText from "../Elements/GText";
import { StringsContext } from "../DataFactory/useStrings";
// import Modal from "react-modal";
// import LocalizationForm from "../forms/localizationForm";

export default function ProductEditForm(props) {
  const [strings] = useContext(StringsContext);
  const [initialLoading, setInitialLoading] = useState(true);
  const productTypes = [
    { title: strings.w_basic_info, identifier: "basic_info" },
    { title: strings.w_images, identifier: "images" },
    { title: strings.w_variants, identifier: "variants" },
  ];
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [typeValue, setTypeValue] = useState(productTypes[0].identifier);
  const [productBasicInfo, setProductBasicInfo] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [productImages, setProductImgaes] = useState([]);
  const [variantTypeIndex, setVariantTypeIndex] = useState(0);
  const [productId, setProductId] = useState("");
  const [localizationsArray, setLocalizationsArray] = useState(
    props.data?.localizations || []
  );
  const [localizationVisible, setLocalizationVisible] = useState(false);

  const initialValues = {
    title: "",
    price: "",
    unit: "",
    categories: "",
    store_id: props.storeId,
    stock: 0,
    unlimited: 0,
    discount: 0,
    description: "",
    keywords: "",
    index: 0,
    barcode: "",
    age_restricted: 0,
  };

  const getProductDetail = () => {
    ApiService({ method: "GET", route: "products/" + props.data.id }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code == 1) {
          console.log(response.data.data);
          setProductBasicInfo(response.data.data);
          initialValues.title = response.data.data.title;
          initialValues.price = response.data.data.price;
          initialValues.unit = response.data.data.unit;
          initialValues.categories = response.data.data.categories;
          initialValues.unlimited = response.data.data.unlimited;
          initialValues.stock = response.data.data.stock;
          initialValues.discount = response.data.data.discount;
          initialValues.description = response.data.data.description;
          initialValues.index = response.data.data.index;
          initialValues.barcode = response.data.data?.barcode || "";
          initialValues.tax_class_id = response.data.data?.tax_class_id || "";
          initialValues.keywords = response.data.data.keywords
            ? response.data.data.keywords.split(",")
            : [];
          initialValues.age_restricted =
            response.data.data?.age_restricted || 0;

          setProductVariants(response.data.data?.variants || []);
          setProductImgaes(response.data.data?.images || []);
          setInitialLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    getProductDetail();
  }, []);

  const onSubmit = (values) => {
    setIsBtnDisabled(true);

    let sendData = { ...values };
    sendData.stock = sendData.stock ? sendData.stock : "0";
    sendData.keywords =
      sendData.keywords.length > 0 ? sendData.keywords.toString() : "";
    sendData.localizations = localizationsArray;

    console.log(sendData);

    ApiService({
      method: "PUT",
      route: "products/" + props.data.id,
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setPevisible(false);
        props.onSubmit();
      }
      setIsBtnDisabled(false);
      enqueueSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings.w_required),
    price: Yup.string().required(strings.w_required),
    unit: Yup.string().required(strings.w_required),
    categories: Yup.string().required(strings.w_required),
  });

  if (props.pevisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> {strings.w_edit_product}</div>

      <div
        className="modal-body"
        style={{ padding: "10px 0", overflow: "hidden" }}
      >
        <GTabs
          routes={productTypes}
          active={typeValue}
          type="single"
          onClick={(newVal) => setTypeValue(newVal)}
          style={{ borderBottom: "1px solid #e2e2e2" }}
          option_title="title"
          option_value="identifier"
        ></GTabs>

        {initialLoading === false && (
          <Formik
            initialValues={productBasicInfo}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                {typeValue == "basic_info" && (
                  <div
                    style={{
                      overflow: "overlay",
                      height: "calc(100vh - 160px)",
                      padding: "20px",
                    }}
                  >
                    <FormikControl
                      control="input"
                      type="text"
                      label={strings.w_title}
                      name="title"
                      divstyle={{
                        display: "inline-block",
                        width: "50%",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      }}
                    />

                    {props.categoriesData &&
                    props.categoriesData.length >= 0 ? (
                      <FormikControl
                        control="select"
                        as="select"
                        label={strings.w_category}
                        name="categories"
                        options={props.categoriesData}
                        key_title="title"
                        key_value="id"
                        divstyle={{
                          display: "inline-block",
                          width: "50%",
                          boxSizing: "border-box",
                          verticalAlign: "top",
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <FormikControl
                      control="input"
                      type="text"
                      label={strings.w_unit}
                      name="unit"
                      divstyle={{
                        display: "inline-block",
                        width: "33.3%",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      }}
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      label={strings.w_price}
                      name="price"
                      onChange={(event) => {
                        const re = /^[0-9.\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("price", event.target.value);
                        }
                      }}
                      divstyle={{
                        display: "inline-block",
                        width: "33%",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      }}
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      label={strings.w_discount_in_percentage}
                      name="discount"
                      maxLength={3}
                      onChange={(event) => {
                        const re = /^[0-9.\b]+$/;
                        if (
                          event.target.value &&
                          !re.test(event.target.value)
                        ) {
                          return;
                        } else {
                          setFieldValue("discount", event.target.value);
                        }
                      }}
                      divstyle={{
                        display: "inline-block",
                        width: "33.3%",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                        margin: "0",
                      }}
                    />

                    <FormikControl
                      control="input"
                      type="text"
                      label={strings.w_index}
                      name="index"
                      divstyle={{
                        display: "inline-block",
                        width: "40%",
                        boxSizing: "border-box",
                        verticleAlign: "top",
                      }}
                    />

                    {props.settings.product_barcode_enabled == 1 && (
                      <FormikControl
                        control="input"
                        type="text"
                        label={strings.w_barcode}
                        name="barcode"
                        divstyle={{
                          display: "inline-block",
                          width: "60%",
                          boxSizing: "border-box",
                          verticalAlign: "top",
                        }}
                      />
                    )}

                    <FormikControl
                      control="input"
                      as="textarea"
                      label={strings.w_description}
                      name="description"
                    />

                    <FormikControl
                      control="input"
                      type="text"
                      label={`${strings.w_keywords} (${strings.s_used_in_product_search})`}
                      name="keywords"
                    />

                    <FormikControl
                      control="toggle"
                      label={strings.w_unlimited}
                      isToggled={values.unlimited == "1" ? 1 : 0}
                      name="unlimited"
                      onToggle={() => {
                        let unlimitedVal =
                          values.unlimited === "1" ? true : false;
                        unlimitedVal = !unlimitedVal;
                        unlimitedVal = unlimitedVal === true ? "1" : "0";
                        setFieldValue("unlimited", unlimitedVal);
                      }}
                      style={{
                        width: "25%",
                        boxSizing: "border-box",
                        verticleAlign: "top",
                      }}
                    />
                    {values.unlimited != "1" ? (
                      <FormikControl
                        control="input"
                        type="text"
                        label={strings.w_stock}
                        name="stock"
                        divstyle={{
                          display: "inline-block",
                          width: "35%",
                          boxSizing: "border-box",
                          verticleAlign: "top",
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {props.settings?.tax_class_enabled == "1" && (
                      <FormikControl
                        control="select"
                        as="select"
                        label={strings.w_tax_class}
                        name="tax_class_id"
                        placeholder={strings.w_choose_tax_class}
                        options={props.taxClasses}
                        key_title="title"
                        key_value="id"
                        divstyle={{
                          display: "inline-block",
                          verticalAlign: "top",
                          boxSizing: "border-box",
                          width: "40%",
                        }}
                      />
                    )}

                    {props.settings?.locales &&
                      props.settings?.locales.length > 0 && (
                        <div style={{ margin: "15px 15px" }}>
                          <label>{strings.w_localizations}</label>

                          {localizationsArray.length > 0 ? (
                            <GButton
                              variant="linkable"
                              onClick={() => setLocalizationVisible(true)}
                            >
                              {localizationsArray.length}{" "}
                              {strings.w_localizations_added}
                            </GButton>
                          ) : (
                            <GButton
                              variant="linkable"
                              children={strings.w_add_localization}
                              onClick={() => setLocalizationVisible(true)}
                            />
                          )}
                        </div>
                      )}

                    <GButton
                      type="submit"
                      disabled={isBtnDisabled}
                      variant="condensed"
                      children={strings.w_update}
                      style={{ width: "calc(100% - 30px)", margin: "15px" }}
                    />
                  </div>
                )}
              </Form>
            )}
          </Formik>
        )}

        {typeValue == "images" && (
          <div
            style={{
              overflow: "overlay",
              height: "calc(100vh - 160px)",
              padding: "20px",
            }}
          >
            <GText
              med
              text={`${strings.w_note} :  ${strings.s_use_image_with_size}`}
              style={{
                margin: "5px 15px 15px",
                fontStyle: "italic",
                fontSize: "14px",
              }}
            />
            <div style={{ margin: "15px" }}>
              <ProductImages
                onUpload={(data) => {
                  if (productImages.length == 0) {
                    props.onSubmitImg(data);
                  }
                  setProductImgaes([...productImages, data]);
                }}
                onRemove={(id) => {
                  if (productImages.length == 1) {
                    props.onRemoveImg();
                  } else {
                    props.onSubmitImg(
                      productImages.filter((item) => item.id !== id)[0]
                    );
                  }
                  setProductImgaes(
                    productImages.filter((item) => item.id !== id)
                  );
                }}
                productImages={productImages}
                add={true}
                productId={props.data.id}
              />
            </div>
          </div>
        )}

        {typeValue == "variants" && (
          <div
            style={{
              overflow: "overlay",
              height: "calc(100vh - 160px)",
              padding: "20px",
            }}
          >
            <div style={{ background: "#f8f9fd", padding: "15px" }}>
              <ProductVariants
                variantTypes={props.variantTypes}
                productVariants={productVariants}
                productId={props.data.id}
                setVariantTypeIndex={setVariantTypeIndex}
                selectedIndex={variantTypeIndex}
                handleVariantAdd={(index, data) => {
                  setVariantTypeIndex(index);
                  productVariants[index].options.push(data);
                  // setProductVariants(productVariants);
                }}
                handleVariantEdit={(index, data, i) => {
                  setVariantTypeIndex(index);
                  productVariants[index].options[i] = data;
                  // setProductVariants(productVariants);
                }}
                handleVariantRemove={(index, i) => {
                  setVariantTypeIndex(index);
                  console.log(i);
                  productVariants[index].options.splice(i, 1);
                  setProductVariants(productVariants);
                }}
                isVarStockShow={props.isStockShow}
              />
            </div>
          </div>
        )}
      </div>

      {/* <Modal
        isOpen={localizationVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setLocalizationVisible(false)}
        style={{
          content: {
            width: "600px",
          },
        }}
        contentLabel="Localization Form Modal"
      >
        <LocalizationForm
          localizationVisible={localizationVisible}
          setLocalizationVisible={setLocalizationVisible}
          data={localizationsArray}
          onSubmit={(data) => {
            setLocalizationsArray([...data]);
          }}
          keyArray={
            props.localizableKeys || [
              { title: "Title", value: "title" },
              { title: "Unit", value: "unit" },
            ]
          }
          variables={[]}
          onLocalizationRemove={(ind) => {
            props.onLocalizationRemove(ind);
          }}
          onLocalizationUpdate={(ind, data) => {
            props.onLocalizationUpdate(ind, data);
          }}
        />
      </Modal> */}
    </>
  );
}
