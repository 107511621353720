import React from "react";
import styled from "styled-components";
import { Link } from "react-dom";

const DropdownDiv = styled.div`
  position: absolute;
  overflow-y: auto;
  z-index: 2;
  background: #393a3d;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  margin: 5px;
  box-sizing: border-box;
  width: auto;

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    li {
      padding: 10px 15px;
      border-bottom: 1px solid #444;
      color: #e2e2e2;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background: #000;
      }
      a {
        color: #fff;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 5;
      transform: rotate(270deg);
      margin-top: -20px;
      z-index: -1;
      border-width: 14px;
      border-style: solid;
      border-color: rgba(255, 255, 255, 0);
      border-left-color: #393a3d;
    }
  }
`;

export default function GDropdown(props) {
  return (
    <DropdownDiv key={props.id} className={props.className} id={props.id}>
      <ul>
        {props.listData.map((list, i) => {
          return (
            <React.Fragment key={i}>
              {list.link ? (
                <li
                  key={i}
                  onClick={() => {
                    props.onSelectValue();
                  }}
                >
                  <a href={list.link} target="_blank">
                    {list.title}
                  </a>
                 </li>
              ) : (
                <li
                  key={i}
                  onClick={() => {
                    props.onSelectValue(list.value);
                  }}
                >
                  {list.title}
                </li>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </DropdownDiv>
  );
}
