import React, { useEffect, useState } from "react";
import GText from "../Elements/GText";
import OrderTrack from "./googleMap";
import { createSocketConnection } from "../useLaravelPusher";

export default function OrderTracking(props) {
  const [agentInfo, setAgentInfo] = useState(props.agent || {});
  useEffect(() => {
    console.log(props);
    setAgentInfo(props.agent || {});
  }, [props]);

  useEffect(() => {
    createSocketConnection();
    window.Echo.channel("driver-location." + props.agent?.id).listen(
      "BCLocationUpdated",
      function (e) {
        console.log(e);
        setAgentInfo({
          ...agentInfo,
          latitude: JSON.stringify(e?.location?.latitude),
          longitude: JSON.stringify(e?.location?.longitude),
        });
      }
    );
  }, []);

  if (props.odTrackvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">Order Tracking #{props.orderId}</div>
      <div className="modal-body">
        <OrderTrack taskData={props.taskData} agent={agentInfo} />
      </div>
    </>
  );
}
