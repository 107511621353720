import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { StringsContext } from "../DataFactory/useStrings";
import { GCardShimmer } from "../Elements/gShimmer";
import { Card } from "../styledComponents/cardStyles";
import GText from "../Elements/GText";
import { ApiService } from "../services";
import { SettingsContext } from "../DataFactory/useSettings";
import styled from "styled-components";
import GEmpty from "../Elements/gEmpty";
import GAvtar from "../Elements/GAvtar";
import { Link } from "react-router-dom";
import GConfirmDialog from "../Elements/gConfirmDialog";
import { useSnackbar } from "notistack";

const Li = styled.li`
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 10px 0;
  padding: 12px 25px;
  background: #f4f5f9;
  border-radius: 4px;
`;

function ActiveOrders() {
  const { enqueueSnackbar } = useSnackbar();
  const [strings, language] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [ordersData, setOrderData] = useState([]);
  const [orderPaginationData, setOrderPaginationData] = useState("");
  const [updatStatusVal, setUpdateStatusVal] = useState("");
  const [slectedOrderId, setSelectedOrderId] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  function getOrders() {
    ApiService({
      method: "GET",
      route: "orders",
      body: { order_status: "active", per_page: 100 },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data?.data?.data || []);
          setOrderPaginationData(response.data?.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    setIsLoading(true);
    getOrders();
  }, [language]);

  function updateOrderStatus() {
    ApiService({
      method: "POST",
      route: "order/" + updatStatusVal + "/" + slectedOrderId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getOrders();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className={"mainInnerView"}>
      <h1>{strings.w_active_orders} </h1>

      {isLoading == true ? (
        <GCardShimmer />
      ) : (
        <div style={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Card
                style={{
                  border: "1px solid #e8e8e8",
                }}
              >
                <GText
                  g3
                  semib
                  text={`${strings.w_preparing} (${
                    ordersData.filter((o) => o.order_status != "ready").length
                  })`}
                  style={{
                    color: "#333",
                    fontWeight: 550,
                    marginBottom: "10px",
                  }}
                />
                <ul
                  style={{
                    padding: "0",
                    margin: 0,
                    height: "65vh",
                    overflow: "overlay",
                  }}
                >
                  {ordersData &&
                  ordersData.filter((o) => o.order_status != "ready").length >
                    0 ? (
                    ordersData
                      .filter((o) => o.order_status != "ready")
                      .map((order, i) => {
                        return (
                          <Li key={i}>
                            <div>
                              <Link
                                to={{
                                  pathname: "/order/" + order.id,
                                  state: "aorder",
                                }}
                              >
                                <GText bold theme text={`#${order.id}`} />
                              </Link>
                              <GText text={`#${order.customer?.title}`} />
                            </div>
                            <div style={{ textAlign: "right", flex: 1 }}>
                              <GText
                                bold="true"
                                text={`${settings.currency_symbol}${order.amount_display}`}
                              />
                              <GText
                                text={`${order.product_count} ${strings.w_items}`}
                              />
                            </div>
                            <div
                              style={{ textAlign: "center", width: "100px" }}
                            >
                              <span
                                className="orderStatus"
                                style={{
                                  color: "#" + order.status.color1,
                                  background: "#" + order.status.color2,
                                  padding: "6px 15px",
                                  borderRadius: "6px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setSelectedOrderId(order.id);
                                  setUpdateStatusVal(
                                    order.order_status === "pending"
                                      ? "accept"
                                      : order.order_status === "accepted"
                                      ? "ready"
                                      : ""
                                  );
                                  setDialogOpen(true);
                                }}
                              >
                                {order.order_status === "pending"
                                  ? strings.w_accept
                                  : order.order_status === "accepted"
                                  ? strings.w_ready
                                  : "-"}
                              </span>
                            </div>
                          </Li>
                        );
                      })
                  ) : (
                    <GEmpty />
                  )}
                </ul>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card
                style={{
                  border: "1px solid #e8e8e8",
                }}
              >
                <GText
                  g3="true"
                  semib="true"
                  text={`${strings.w_ready} (${
                    ordersData.filter((o) => o.order_status === "ready").length
                  })`}
                  style={{
                    color: "#333",
                    fontWeight: 550,
                    marginBottom: "10px",
                  }}
                />
                <ul
                  style={{
                    padding: "0",
                    margin: 0,
                    height: "65vh",
                    overflow: "overlay",
                  }}
                >
                  {ordersData &&
                  ordersData.filter((o) => o.order_status === "ready").length >
                    0 ? (
                    ordersData
                      .filter((o) => o.order_status === "ready")
                      .map((order, i) => {
                        return (
                          <Li key={i}>
                            <div>
                              <Link
                                to={{
                                  pathname: "/order/" + order.id,
                                  state: "aorder",
                                }}
                              >
                                <GText
                                  bold="true"
                                  theme
                                  text={`#${order.id}`}
                                />
                              </Link>
                              <GText text={`#${order.customer?.title}`} />
                            </div>
                            {order.order_type == "home_delivery" ? (
                              <>
                                {order.pickup_agent?.id ? (
                                  <>
                                    <div
                                      style={{ textAlign: "right", flex: 1 }}
                                    >
                                      <GText
                                        bold={true}
                                        text={`${order.pickup_agent?.title} Arriving In`}
                                      />
                                      <span
                                        style={{
                                          color: "#fff",
                                          background: "#39B846",
                                          padding: "2px 10px 3px",
                                          borderRadius: "20px",
                                          display: "inline-block",
                                          marginTop: "2px",
                                        }}
                                      >
                                        {order.pickup_agent?.eta_display}
                                      </span>
                                    </div>
                                    <div style={{ width: "40px" }}>
                                      <GAvtar
                                        title={order?.pickup_agent?.title}
                                        width={"40px"}
                                        height={"40px"}
                                        src={order?.pickup_agent?.photo}
                                        imgType="user"
                                        alt={order?.pickup_agent?.first_name}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div style={{ textAlign: "right", flex: 1 }}>
                                    <GText
                                      med
                                      text={strings.s_no_driver_assigned_yet}
                                      style={{ fontStyle: "italic" }}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <div style={{ textAlign: "right", flex: 1 }}>
                                <span
                                  style={{
                                    color: "#fff",
                                    background: "#D8AE36",
                                    padding: "2px 10px 3px",
                                    display: "inline-block",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {strings.w_store_pickup}
                                </span>
                              </div>
                            )}
                          </Li>
                        );
                      })
                  ) : (
                    <GEmpty />
                  )}
                </ul>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.w_update_status}
          text={strings.s_update_status_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            updateOrderStatus();
          }}
        ></GConfirmDialog>
      )}
    </div>
  );
}

export default ActiveOrders;
