import React, { useState, useEffect, useContext } from "react";
import { SettingsContext } from "../DataFactory/useSettings";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import { ReactComponent as Edit } from "../assets/images/icons/edit.svg";
import { ApiService } from "../services";
import { TableContainer } from "@mui/material";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import { useSnackbar } from "notistack";
import GConfirmDialog from "../Elements/gConfirmDialog";
import Modal from "react-modal";
import HolidayHourForm from "../forms/holidayHourForm";
import useDataFactory from "../useDataFactory";
import GButton from "../Elements/GButton";
import useDateFormatter from "../DataFactory/useDateFormatter";
import { StringsContext } from "../DataFactory/useStrings";

export default function HolidayHours() {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const { parseDate } = useDateFormatter();
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedHolidayHourId, setSelectedHolidayHourId] = useState("");
  const [whvisible, setWhvisible] = useState(false);
  const [selectedHolidayHour, setSelectedHolidayHour] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);

  const columns = [
    { id: "start_time", label: strings.w_start_date, minWidth: 150 },
    { id: "end_time", label: strings.w_end_date, minWidth: 150 },
    { id: "actions", label: strings.w_actions, minWidth: 60, align: "center" },
  ];

  const {
    loading,
    data: workingHoursData,
    setData,
    Placeholder,
    TableShimmer,
  } = useDataFactory("holiday_hours", false);

  const deleteHolidayHour = () => {
    ApiService({
      method: "DELETE",
      route: "holiday_hours/" + selectedHolidayHourId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = workingHoursData.findIndex(
            (p) => p.id == selectedHolidayHourId
          );
          if (selectedIndex > -1) {
            workingHoursData.splice(selectedIndex, 1);
          }
        }
        enqueueSnackbar(response.data.message);
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getFormattedTime(time) {
    let dateTime = parseDate(time, "current");

    return dateTime?.date + " " + dateTime.time;
  }

  return (
    <div className={"mainInnerView"}>
      <GButton
        variant="condensed"
        children={strings.w_add}
        onClick={() => {
          setIsEmpty(true);
          setSelectedHolidayHour({});
          setWhvisible(true);
        }}
        style={{
          float: "right",
        }}
      />
      <h1>{strings.w_holiday_hours}</h1>

      <div className="listPaper" style={{ marginTop: "0" }}>
        {loading === true ? (
          <TableShimmer />
        ) : workingHoursData && workingHoursData.length > 0 ? (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {workingHoursData.map((c) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData>
                        {c.starts ? getFormattedTime(c.starts) : "-"}
                      </TableRowData>
                      <TableRowData>
                        {c.ends ? getFormattedTime(c.ends) : "-"}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedHolidayHour(c);
                            setIsEmpty(false);
                            setWhvisible(true);
                          }}
                        >
                          <Edit fontSize="small" style={{ width: 20 }} />
                        </GButton>
                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedHolidayHourId(c.id);
                            setDialogOpen(true);
                          }}
                        >
                          <Delete fontSize="small" style={{ width: 20 }} />
                        </GButton>
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Placeholder />
        )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.s_delete_holiday_hour_title}
          text={strings.s_delete_holiday_hour_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteHolidayHour();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={whvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setWhvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Holiday Hour Form Modal"
      >
        <HolidayHourForm
          whvisible={whvisible}
          setWhvisible={setWhvisible}
          isEmpty={isEmpty}
          data={selectedHolidayHour}
          onSubmit={(data) => {
            let selectedIndex = workingHoursData.findIndex(
              (p) => p.id == data.id
            );
            if (selectedIndex > -1) {
              workingHoursData[selectedIndex] = { ...data };
              setData([...workingHoursData]);
            } else {
              workingHoursData.push(data);
              setData([...workingHoursData]);
            }
          }}
        />
      </Modal>
    </div>
  );
}
