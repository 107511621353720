import React, { useState } from "react";

import { useSnackbar as useDefaultSnackbar } from "notistack";
import { Snackbar } from "../Elements/GSnackbar";
import url from "../assets/audio/order_assign_sound.wav";

export const useEnqueueSnackbar = () => {
  const [audio] = useState(new Audio(url));
  const { enqueueSnackbar } = useDefaultSnackbar();

  const pushSnackbar = (
    message,
    options = {
      variant: "info" || "default" || "success" || "error" || "warning",
    }
  ) => {
    enqueueSnackbar(message, {
      variant: "info",
      persist: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      ...options,
      content: (key) => {
        // destructure the options we need from the extended options
        // object, and provide a default case if we didn't provide any
        const { variant } = options || { variant: "info" };
        return (
          <Snackbar
            id={key}
            message={message}
            variant={variant || "default"}
            options={options}
            audio={audio}
          />
        );
      },
    });
  };

  return pushSnackbar;
};
