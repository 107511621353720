import React, { useContext, useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { StringsContext } from "../DataFactory/useStrings";

export default function StaffForm(props) {
  const [strings] = useContext(StringsContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    first_name: !props.isEmpty ? props.data.first_name : "",
    last_name: !props.isEmpty ? props.data.last_name : "",
    email: !props.isEmpty ? props.data.email : "",
    phone: !props.isEmpty ? props.data.phone : "",
    password: "",
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    if (!props.isEmpty) {
      if (!values.password) {
        delete values.password;
      }
    }
    console.log(values);

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "store/staff/" + props.data.id
      : "store/staff";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.onSubmit(response.data.data);
          props.setSFormVisible(false);
        }
        setIsDisabled(false);
        enqueueSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required(strings.w_required)
      .min(2, strings.s_first_name_should_be_atleast_2_characters),
    email: Yup.string()
      .email(strings.s_invalid_email_address)
      .required(strings.w_required),
    password: !props.isEmpty
      ? ""
      : Yup.string()
          .required(strings.w_required)
          .min(6, strings.s_password_is_too_short),
  });

  if (props.sFormVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? strings.w_edit_staff : strings.w_create_staff}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ values, isValid, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={`${strings.w_first_name}*`}
                name="first_name"
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_last_name}
                name="last_name"
              />
              <FormikControl
                control="input"
                type="email"
                label={`${strings.w_email_id}*`}
                name="email"
              />

              <FormikControl
                control="input"
                type="text"
                label={
                  !props.isEmpty ? strings.w_password : strings.w_password + "*"
                }
                name="password"
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_phone}
                name="phone"
                onChange={(event) => {
                  const re = /^[0-9+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              />
              <br />
              <GButton
                variant="condensed"
                disabled={isDisabled}
                style={{
                  margin: "0 15px",
                  width: "95%",
                  color: "#fff",
                }}
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
