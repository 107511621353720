import React, { useEffect, useState, useRef, useContext } from "react";
import useDateFormatter from "../DataFactory/useDateFormatter";
import styled from "styled-components";
import moment from "moment/moment";
import { ReactComponent as CalendarIcon } from "../assets/images/calendar.svg";
import { ReactComponent as Close } from "../assets/images/close.svg";
import { ReactComponent as Search } from "../assets/images/search.svg";
import { ReactComponent as ArrowDropDown } from "../assets/images/arrowDown.svg";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import GView from "./GView";
import GText from "./GText";
import { StringsContext } from "../DataFactory/useStrings";

const SuggestionDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 5px;
  //   &:hover {
  background: #2e6dbd;
  p {
    color: #fff !important;
    font-size: 14px;
  }
  svg {
    fill: #fff;
  }
  //   }
`;

const Input = styled.input`
  width: calc(100% - 42px);
  height: 30px;
  background: #f5f5f5;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #777;
  padding-left: 7px;
`;

const getDate = (d) => {
  return moment(d).format("YYYY-MM-DD");
};

export default function GDateTimePicker(props) {
  const [strings] =useContext(StringsContext);
  const { parseDate } = useDateFormatter();
  const [date, setDate] = useState();
  const [searchWord, setSearchWord] = useState("");
  const wrapperRef = useRef(null);
  const [showPicker, setShowPicker] = useDetectOutsideClick(wrapperRef, false);
  const [suggestionValue, setSuggestionValue] = useState();

  useEffect(() => {
    if (props.value) {
      let d = parseDate(props.value, props.timezone);
      setDate(props.isDateOnly ? d?.date : d?.date + " " + d?.time);
    }
  }, [props]);

  return (
    <div ref={wrapperRef} style={{ position: "relative" }}>
      <GView
        onClick={() => {
          setShowPicker(true);
          setSearchWord("");
        }}
        style={Object.assign(
          {
            border: "1px solid #e2e2e2",
            padding: "6px 10px",
            minHeight: "22px",
          },
          props.mainDivStyle
        )}
      >
        {props.label && (
          <label
            style={{ fontSize: "12px", margin: 0, padding: 0, color: "#aaa" }}
          >
            {props.label}
          </label>
        )}
        <span style={{ fontSize: "14px", color: date ? "#000" : "#ccc" }}>
          {date || props.placeholderText || ""}
        </span>
      </GView>
      {showPicker === true ? (
        <GView
          style={{
            position: "absolute",
            top: props.label ? 55 : 38,
            background: "#fff",
            borderRadius: "6px",
            boxShadow: "rgba(187, 187, 187, 0.36) -1px -1px 10px",
            border: "1px solid #f5f5f5",
            width: "100%",
            zIndex: 9,
            boxSizing: "border-box",
            padding: "5px",
          }}
        >
          <GView
            style={{
              background: "#f5f5f5",
              padding: "2px 9px",
              boxSizing: "border-box",
              borderRadius: "6px",
            }}
          >
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                width: "15px",
                height: "15px",
                fill: "#ccc",
              }}
            />
            <Input
              value={searchWord}
              onChange={(e) => {
                setSearchWord(e.target.value);
                let formattedDate = parseDate(e.target.value || "");
                console.log(formattedDate);
                setSuggestionValue({
                  ...formattedDate,
                });
              }}
              placeholder={strings.w_when}
            />
            {searchWord && (
              <Close
                fontSize="small"
                style={{
                  verticalAlign: "middle",
                  width: "10px",
                  height: "10px",
                  fill: "#aaa",
                  cursor: "pointer",
                  paddingLeft: 5,
                }}
                onClick={() => {
                  setSearchWord("");
                  setSuggestionValue();
                }}
              />
            )}
          </GView>
          {suggestionValue?.date ? (
            <SuggestionDiv
              onClick={() => {
                setDate(
                  props.isDateOnly
                    ? suggestionValue.date
                    : suggestionValue.date + " " + suggestionValue.time
                );
                props.onChange(
                  props.isDateOnly
                    ? getDate(suggestionValue.timestamp)
                    : suggestionValue.timestamp
                );

                setShowPicker(false);
                setSearchWord("");
                // setSuggestionValue();
              }}
            >
              <CalendarIcon style={{ width: 15, height: 15 }} />
              <GText text={suggestionValue?.date} />
              {!props.isDateOnly && (
                <GText
                  text={suggestionValue?.time}
                  style={{ marginLeft: "auto" }}
                />
              )}
            </SuggestionDiv>
          ) : (
            <SuggestionDiv>
              <GText
                med
                text={"---"}
                style={{ fontSize: "14px", padding: 0 }}
              />
            </SuggestionDiv>
          )}
        </GView>
      ) : (
        ""
      )}
    </div>
  );
}
