import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Info } from "../assets/images/info.svg";
import GText from "./GText";
import { StringsContext } from "../DataFactory/useStrings";

const CancelBox = styled.div`
  color: #000;
  background: #f1e1e2;
  font-size: 15px;
  padding: 10px;
  width: 220px;
  font-weight: 400;
  float: right;
  border: 1px solid #e26d69;
  margin-bottom: 10px;
  svg {
    fill: #e26d69;
    padding-right: 10px;
    vertical-align: top;
    width: 20px;
    height: 20px;
  }
  p {
    color: #333;
    font-size: 13.5px;
    margin: 0px;
    line-height: 19px;
    display: inline-block;
    width: 80%;
    span {
      color: #e26d69;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export default function GCancelBox(props) {
  const [strings] = useContext(StringsContext);
  return (
    <CancelBox>
      {props.handleClick ? (
        <React.Fragment>
          <Info />
          <p>
            {strings.s_having_issue_with_this_order}{" "}
            <span onClick={props.handleClick}>{strings.w_click_here}</span>{" "}
            {strings.s_to_cancel_it}.
          </p>
        </React.Fragment>
      ) : props.data ? (
        <React.Fragment>
          <GText
            semi
            text={strings.w_order_cancelled + " - "}
            style={{ color: "#e26d69" }}
          />
          <GText
            g5
            text={props.data.reason}
            style={{ color: "#e26d69", lineHeight: "17px", marginTop: "2px" }}
          />
        </React.Fragment>
      ) : (
        ""
      )}
    </CancelBox>
  );
}
