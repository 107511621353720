import React, { useRef, useContext, useState, useEffect } from "react";
import { View, Pressable, I18nManager, Text } from "react-native";
import { ReactComponent as Language } from "../assets/images/language.svg";
import GText from "../Elements/GText";
import { StringsContext } from "../DataFactory/useStrings";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import useLocalStorage from "../DataFactory/useLocalStorage";
import styled from "styled-components";
import { SettingsContext } from "..//DataFactory/useSettings";

const Div = styled.div`
  &.languageDiv {
    background: #fff;
    border-radius: 4px;
    padding: 10px 0;
  }
`;

const ChangeLanguage = () => {
  const [strings, language, isRTL, availableLaunguages, setLanguage] =
    useContext(StringsContext);
  const refRBSheet = useRef();
  const { settings, setSettings } = useContext(SettingsContext);
  const [lang, setLang] = useLocalStorage("language");
  const [isLOpen, setIsLOpen] = useDetectOutsideClick(refRBSheet, false);

  const getLanguage = (language) => {
    return (
      availableLaunguages.find((t) => t.languageCode == language) ||
      availableLaunguages[0]
    );
  };

  useEffect(() => {
    // console.log(language);
    if (settings?.user?.id && !language) {
      setLang(language);
      setLanguage(language);
      if (["ar"].includes(language)) {
        I18nManager.allowRTL(true);
        I18nManager.forceRTL(true);
      } else {
        I18nManager.allowRTL(false);
        I18nManager.forceRTL(false);
      }
    }
  }, [settings]);

  return (
    <div style={{ marginRight: "20px", position: "relative" }} ref={refRBSheet}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{ padding: 5, cursor: "pointer" }}
          onClick={() => setIsLOpen(!isLOpen)}
        >
          <div
            style={{
              display: "inline-flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#fff",
            }}
          >
            <Language
              style={{
                width: "18px",
                height: "18px",
                paddingRight: "2px",
                fill: "#fff",
                transform: I18nManager.isRTL ? "scale(-1, 1)" : "scale(1, 1)",
              }}
            />
            &nbsp;
            <Text
              style={{
                marginBottom: 0,
                lineHeight: "26px",
                textTransform: "uppercase",
                color: "#fff",
              }}
            >
              {getLanguage(language)?.languageCode}
            </Text>
          </div>
        </div>
      </View>
      {isLOpen == true && (
        <div
          height={50 + 50 * availableLaunguages.length}
          style={{
            position: "absolute",
            right: "0",
            top: "47px",
            minWidth: "150px",
            textAlign: "left",
            zIndex: 9,
            borderRadius: "4px",
            boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
          }}
        >
          <Div className="languageDiv">
            {availableLaunguages.map((c, i) => (
              <Pressable
                style={{ paddingVertical: 7, paddingHorizontal: 15 }}
                key={i}
                onPress={() => {
                  setLang(c.languageCode);
                  setLanguage(c.languageCode);
                  if (["ar"].includes(c.languageCode)) {
                    I18nManager.allowRTL(true);
                    I18nManager.forceRTL(true);
                  } else {
                    I18nManager.allowRTL(false);
                    I18nManager.forceRTL(false);
                  }
                  // RNRestart.Restart();
                  setIsLOpen(false);
                }}
              >
                <GText text={c.title} style={{ margin: "5px 0" }} />
              </Pressable>
            ))}
          </Div>
        </div>
      )}
    </div>
  );
};

export default ChangeLanguage;
