import React, { useState, useEffect, useContext } from "react";
import "../assets/css/invoice.css";
import { ApiService, ImgUrl, addDefaultLogo } from "../services";
import { useParams } from "react-router-dom";
import useSettings from "../useSettings";
import { StringsContext } from "../DataFactory/useStrings";

function OrderInvoice(props) {
  const [strings] = useContext(StringsContext);
  const setting = useSettings();
  let { orderId } = useParams();
  const [invoiceData, setInvoiceData] = useState({});

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "orders/" + orderId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setInvoiceData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("a_m_AccessToken")) {
      getOrderDetail();
    } else {
      window.location = "/";
    }
  }, []);

  return (
    <div className="invoiceDiv">
      <div
        className="flex"
        style={{
          width: "40%",
        }}
      >
        <img
          src={ImgUrl("logo") + "/" + setting.invoice_logo}
          alt="logo"
          onError={addDefaultLogo}
        />

        <label>{setting.store?.title || ""}</label>
        <p>{setting.store?.address || ""}</p>
      </div>
      <div
        className="flex"
        style={{
          width: "60%",
        }}
      >
        <div>
          <p>{setting.invoice_header_text}</p>
          <div
            className="flex"
            style={{
              width: "50%",
            }}
          >
            <label>{strings.w_order_date}</label>
            <p>{invoiceData.created ? invoiceData.created_full : ""}</p>

            <br />
            <label>{strings.w_invoice_number}</label>
            <p>{"#" + orderId}</p>
          </div>
          <div
            className="flex"
            style={{
              width: "50%",
            }}
          >
            <label>{strings.w_invoice}</label>
            <p>{invoiceData.customer ? invoiceData.customer.title : ""}</p>
            <p>{invoiceData.customer ? invoiceData.customer.phone : ""}</p>
            <p>
              {invoiceData.delivery
                ? invoiceData.delivery.delivery_address
                : ""}
            </p>
          </div>
        </div>
      </div>

      <div>
        <table>
          <thead>
            <tr>
              <th>{strings.w_item}</th>
              <th>{strings.w_quantity}</th>
              <th>{strings.w_unit_price}</th>
              <th style={{ textAlign: "right" }}>{strings.w_total_price}</th>
            </tr>
          </thead>

          {invoiceData.order_products ? (
            <tbody>
              {invoiceData.order_products.map((item, ind) => {
                return (
                  <tr>
                    <td>
                      {item.title}
                      <br />
                      {item.variants.map((v, $index) => {
                        return (
                          <small
                            key={v.id}
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "400",
                              display: "inline-block",
                              paddingRight: "5px",
                              lineHeight: "20px",
                            }}
                          >
                            <span style={{ color: "#555" }}>{v.value}</span>
                            {item.variants.length > $index + 1 ? (
                              <span>, </span>
                            ) : (
                              ""
                            )}
                          </small>
                        );
                      })}
                    </td>
                    <td>{item.quantity}</td>
                    <td>
                      {item.price}/{item.unit}
                    </td>
                    <td>
                      {setting?.currency_symbol}
                      {(item.quantity * item.price).toFixed(2)}
                    </td>
                  </tr>
                );
              })}

              <tr className="nopadding noheight payment">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {invoiceData.payment_summary?.data.map((payment, ind) => {
                return (
                  <tr className={payment.title + " payment"}>
                    <td></td>
                    <td></td>
                    <td>{payment.title}</td>
                    <td>{payment.value}</td>
                  </tr>
                );
              })}

              <tr className="payment">
                <td></td>
                <td></td>
                <td>
                  {strings.w_total} ({invoiceData.gateway_display})
                </td>
                <td>
                  {setting?.currency_symbol}
                  {invoiceData.payment_summary.total_display}
                </td>
              </tr>
            </tbody>
          ) : (
            ""
          )}
        </table>
      </div>
      <br />

      <label>
        {strings.s_thankyou_for_using_the_services_of} {setting?.project_name}.
      </label>
      <p>{strings.s_powered_by} Ambar</p>
      <br />
      <p>{setting.invoice_footer_text}</p>
    </div>
  );
}

export default OrderInvoice;
